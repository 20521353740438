import { Card, Checkbox, Input, Pagination, message } from 'antd'
import { MailOutlined, PhoneOutlined } from '@ant-design/icons'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import { useState, useRef, useEffect } from 'react'
import axios from '../../../axios/axiosInstance'
import { useNavigate } from 'react-router-dom'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import SearchIcon from '@mui/icons-material/Search'
import avatar from '../../../assets/img/avatar.jpg'
import cv from '../../../assets/img/cv.png'
import xlsIcon from '../../../assets/img/xls.png'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import ModalCv from './ModalCv'
import LoadingSearch from './LoadingSearch'
import CreateCandidatModal from '../CreateCandidatModal'
import CandidatModal from '../CandidatModal'
import FilterCandidat from '../FilterCandidat'

const CVTheque = () => {
  const [documentCount, setDocumentCount] = useState(0)
  const [isPld, setIsPld] = useState(false)
  const searchInputRef = useRef(null)
  const [candidates, setCandidates] = useState([])
  const [originalCandidates, setOriginalCandidates] = useState([])
  const [searchWord, setSearchWord] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [isOpenFilter, setIsOpenFilter] = useState(false)
  const [isOpenCandidateModal, setIsOpenCandidateModal] = useState(false)
  const [isOpenCreateCandidateModal, setIsOpenCreateCandidateModal] =
    useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [isModalCV, setIsModalCV] = useState(false)
  const [cvData, setCvData] = useState('')
  const [cvType, setCvType] = useState('')

  const openCreateCandidateModal = (user) => {
    setSelectedUser(user)
    setIsOpenCreateCandidateModal(true)
  }

  const openCandidateModal = (user) => {
    setSelectedUser(user)
    setIsOpenCandidateModal(true)
  }

  useEffect(() => {
    setIsLoading(true)
    loadCandidates()
  }, [])

  const loadCandidates = () => {
    axios
      .get('tempworker-candidate/count')
      .then((res) => setDocumentCount(res.data))
      .catch((e) => {})

    axios
      .get('tempworker-candidate')
      .then((res) => {
        setCandidates(res.data)
        let sizeInKB = 0
        for (let x of res.data) {
          const jsonString = JSON.stringify(x)
          const sizeInBytes = new TextEncoder().encode(jsonString).length
          sizeInKB += sizeInBytes / 1024
        }
        console.log(`Array size: ${sizeInKB.toFixed(2)} KB`)
        setTimeout(() => {
          setIsLoading(false)
        }, 500)
        setOriginalCandidates(res.data)
      })
      .catch((e) => {
        console.log(e)
        if (e.response && e.response.data.statusCode === 403) {
          localStorage.clear()
          navigate('/login')
        } else {
          message.error("Une erreur s'est produite !")
        }
      })
  }

  const onChangePage = (page, pageSize) => {
    setIsLoading(true)
    axios
      .get(`tempworker-candidate/paginate/${page - 1}`)
      .then((res) => {
        setCandidates(res.data)
        setIsLoading(false)
        setCurrentPage(page)
      })
      .catch((e) => {
        console.log(e)
        setIsLoading(false)
      })
  }

  const showCv = (e, candidate) => {
    e.stopPropagation()
    const candidateId = candidate._id
    setLoadingSearch(true)
    axios
      .get(`cv/${candidateId}`)
      .then((res) => {
        console.log(res)
        setCvData(res.data.data)

        const header = res.data.data?.substring(0, 5) || ''

        // Check if the file is a PDF, JPEG, or PNG based on the base64 header
        if (header === 'JVBER') {
          setCvType('pdf') // It's a PDF
        } else if (header.startsWith('/9j/')) {
          setCvType('jpeg') // It's a JPEG image
        } else if (header.startsWith('iVBOR')) {
          setCvType('png') // It's a PNG image
        } else {
          console.log('Unsupported file type')
        }
        setSelectedUser(candidate)
        setIsModalCV(true)
      })
      .catch((e) => console.log(e))
      .finally(() => setLoadingSearch(false))
  }

  const handleExportClick = () => {
    exportToExcel(candidates)
  }

  const exportToExcel = (data) => {
    const newData = data.map((candidate) => {
      const {
        firstname,
        lastname,
        phone,
        email,
        postCode,
        town,
        qualifications,
      } = candidate
      return {
        Nom: lastname,
        Prénom: firstname,
        Téléphone: phone,
        Email: email,
        'Code postal': postCode,
        Ville: town,
        qualifications: qualifications.join(' - '),
      }
    })

    const workbook = XLSX.utils.book_new()
    const sheet = XLSX.utils.json_to_sheet(newData)

    XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet 1')

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    })

    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    saveAs(blob, 'data.xlsx')
  }

  const selectSearchWord = (word, event) => {
    if (word.length === 0) {
      setCandidates(originalCandidates)
    }
    setSearchWord(word)

    if (event.key === 'Enter') {
      search()
    }
  }

  const selectSearchWordPaste = (e) => {
    const pastedData = e.clipboardData.getData('text');
    if (pastedData.length === 0) {
      setCandidates(originalCandidates)
    }
    setSearchWord(pastedData)

  }

  const search = () => {
    if (searchWord.length === 0) {
      setCandidates(originalCandidates)
      setCurrentPage(1)
    }

    setLoadingSearch(true)
    axios
      .post('tempworker-candidate/find-by-text', { query: searchWord, isPld })
      .then((res) => {
        setCandidates(res.data)
        setLoadingSearch(false)
      })
      .catch((e) => setLoadingSearch(false))
  }

  return (
    <div>
      {isOpenCandidateModal && (
        <CandidatModal
          isOpen={isOpenCandidateModal}
          setIsOpen={setIsOpenCandidateModal}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          setCandidates={setCandidates}
          candidates={candidates}
        />
      )}

      {isOpenCreateCandidateModal && (
        <CreateCandidatModal
          candidates={candidates}
          setCandidates={setCandidates}
          isOpen={isOpenCreateCandidateModal}
          setIsOpen={setIsOpenCreateCandidateModal}
          openCandidateModal={openCandidateModal}
        />
      )}

      {isOpenFilter && !isOpenCandidateModal && (
        <FilterCandidat
          originalCandidates={originalCandidates}
          setCandidates={setCandidates}
          open={isOpenFilter}
          setIsOpen={setIsOpenFilter}
        />
      )}

      {isModalCV && (
        <ModalCv
          setIsModalCV={setIsModalCV}
          cvType={cvType}
          cvData={cvData}
          selectedUser={selectedUser}
        />
      )}

      {loadingSearch && <LoadingSearch />}

      <div className="my-6">
        <p className="text-2xl font-semibold ml-2">{documentCount} Candidats</p>
      </div>
      <div className="text-left flex space-x-1 my-10 h-10 items-end w-full justify-end pr-10">
        <div className="flex flex-col space-y-2">
          <div className="flex items-center space-x-2">
            <Checkbox checked={isPld} onChange={() => setIsPld(!isPld)} />
            <p>Interimaire</p>
          </div>
          <div className="flex items-center justify-center space-x-2">
            <Input
              ref={searchInputRef}
              className={`w-80  rounded-md border border-gray-200 focus:outline-none`}
              placeholder="Recherche"
              style={{ outline: 'none' }}
              onKeyDown={(e) => selectSearchWord(e.target.value, e)}
              onPaste={(e) => selectSearchWordPaste(e)}
            />
            <button
              onClick={() => search()}
              className="bg-green-500 text-white p-2 rounded-md"
            >
              Rechercher
            </button>
          </div>
        </div>
        {/* <SearchIcon
                  onClick={() => showSearchInput(showSearch)}
                  className="text-gray-500 text-3xl hover:cursor-pointer"
                /> */}

        <FilterListOutlinedIcon
          className="w-8 h-8 text-gray-500 hover:cursor-pointer"
          onClick={() => setIsOpenFilter(!isOpenFilter)}
        />
        <img
          src={xlsIcon}
          onClick={handleExportClick}
          className="w-8 h-8 mx-2 hover:cursor-pointer"
        />
        <AddCircleOutlineIcon
          className="w-8 h-8 text-gray-500 hover:cursor-pointer"
          onClick={openCreateCandidateModal}
        />
      </div>

      {candidates.length > 0 && (
        <div className="p-4">
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
            {candidates.map((candidate, key) => (
              <Card
                onClick={() => openCandidateModal(candidate)}
                key={key}
                className="bg-white border border-gray-300 rounded-md overflow-hidden hover:cursor-pointer "
                loading={isLoading}
              >
                <div className="flex flex-col justify-between h-full p-4">
                  <div>
                    <div className="flex items-start justify-between mb-4">
                      <div className="flex items-center space-x-3">
                        <img
                          src={
                            candidate.image
                              ? `data:image/png;base64,${candidate.image}`
                              : avatar
                          }
                          alt={`${candidate.firstname} ${candidate.lastname}`}
                          className="w-14 h-14 rounded-full"
                        />
                        <div>
                          <p className="font-bold text-lg flex flex-col items-start capitalize">
                            <span>{candidate.firstname.toLowerCase()} </span>
                            <span>{candidate.lastname.toLowerCase()}</span>
                          </p>
                          {candidate.pld && (
                            <p className="text-red-500 text-sm">
                              #{candidate.pld}
                            </p>
                          )}
                        </div>
                      </div>
                      <img
                        src={cv}
                        alt="CV"
                        className="w-10 h-10 cursor-pointer"
                        onClick={(e) => showCv(e, candidate)}
                      />
                    </div>

                    <div className="flex items-center my-4 space-x-2">
                      <PhoneOutlined className="text-blue-500 text-lg" />
                      <p className="font-semibold">
                        {candidate.phone.replace(/(\d{2})(?=\d)/g, '$1 ')}
                      </p>
                    </div>

                    <div className="flex items-center mb-4 space-x-2">
                      <MailOutlined className="text-blue-500 text-lg" />
                      <p className="font-semibold uppercase">
                        {candidate.email}
                      </p>
                    </div>

                    <div className="flex items-center mb-4 space-x-2">
                      <LocationOnOutlinedIcon className="text-blue-500 text-xl" />
                      <p className="font-semibold uppercase">
                        {candidate.postCode} {candidate.town?.toUpperCase()}
                      </p>
                    </div>

                    <div className="flex items-start mb-4 space-x-2">
                      <WorkOutlineOutlinedIcon className="text-blue-500 text-xl" />
                      <div className="flex flex-col">
                        <p className="font-semibold uppercase">
                          {candidate.qualifications[0]}
                        </p>
                        {/* Render more qualifications if needed */}
                      </div>
                    </div>
                  </div>

                  {candidate.status && (
                    <div className="flex justify-center mt-4">
                      <span
                        className={`text-white text-xs rounded-md text-center px-2 py-1 ${
                          candidate.status === 'Indisponible'
                            ? 'bg-orange-400'
                            : candidate.status === 'Bloqué'
                            ? 'bg-black'
                            : 'bg-green-500'
                        }`}
                      >
                        {candidate.status}
                      </span>
                    </div>
                  )}
                </div>
              </Card>
            ))}
          </div>
        </div>
      )}

      <div className="text-right">
        <Pagination
          className="mt-6 w-full"
          current={currentPage}
          onChange={onChangePage}
          total={documentCount}
          pageSize={48}
        />
      </div>
    </div>
  )
}

export default CVTheque
