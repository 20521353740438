import { SearchBar } from '../utils/SearchBar'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import SearchIcon from '@mui/icons-material/Search'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import { cacesList } from '../Candidat/data/cacesList';
import { habilitationsList } from '../Candidat/data/habilitationsList';
import {
  Checkbox,
  DatePicker,
  Input,
  Modal,
  Select,
  Spin,
  Pagination,
  Popover,
  Button,
  message,
  TreeSelect,
} from 'antd'
import { useEffect, useState } from 'react'
import axiosInstance from '../../axios/axiosInstance'
import { DesktopTimePicker, TimePicker } from '@mui/x-date-pickers'
import qualifications from '../../qualifications/qualifications'
import {
  DeleteForeverOutlined,
  RemoveCircleOutlineRounded,
} from '@mui/icons-material'
import epinone from '../../assets/img/epinone.png'
import epicasque from '../../assets/img/epicasque.png'
import epichaussure from '../../assets/img/epichaussure.png'
import epigilet from '../../assets/img/epigilet.png'
import epimasque from '../../assets/img/epimasque.png'
import epiaudio from '../../assets/img/epiaudio.png'
import epiaudioselected from '../../assets/img/epiaudioselected.png'
import epicasqueselected from '../../assets/img/epicasqueselected.png'
import epichaussureselected from '../../assets/img/epichaussureselected.png'
import epigiletselected from '../../assets/img/epigiletselected.png'
import epimasqueselected from '../../assets/img/epimasqueselected.png'
import epibleuselected from '../../assets/img/epibleuselected.png'
import epigantselected from '../../assets/img/epigantselected.png'
import epiharnaisselected from '../../assets/img/epiharnaisselected.png'
import epilunetteselected from '../../assets/img/epilunetteselected.png'
import epinoneselected from '../../assets/img/epinoneselected.png'
import epiautre from '../../assets/img/epiautre.png'
import epibleu from '../../assets/img/epibleu.png'
import epigant from '../../assets/img/epigant.png'
import epiharnais from '../../assets/img/epiharnais.png'
import epilunette from '../../assets/img/epilunette.png'
import { MailOutlined } from '@ant-design/icons'
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout'
import _ from 'lodash'
import { Puff } from 'react-loader-spinner'
import OrderCandidatModal from '../Candidat/OrderCandidatModal/OrderCandidatModal'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import FilterOrder from './FilterOrder'
import ModalDetail from './ModalDetail'
import TextArea from 'antd/es/input/TextArea'

const Commandes = ({ state }) => {
  const [openModal, setOpenModal] = useState(false)
  const [step, setStep] = useState('1')
  const [clients, setClients] = useState([])
  const [clientAddressesArray, setClientAdressesArray] = useState([])
  const [clientContactsArray, setClientContactsArray] = useState([])
  const [isFilterOrder, setIsFilterOrder] = useState(false)
  const [isOriginal, setIsOriginal] = useState(true)
  const [filter, setFilter] = useState(
    state === 'enCours' ? 'En cours' : 'Toutes',
  )
  const [order, setOrder] = useState({
    epiProvider: '',
    isVehicule: true,
    epis: [],
    primes: [],
    workedDays: [],
    quantity: 1,
  })
  const [orders, setOrders] = useState([])
  const [originalOrders, setOriginalOrders] = useState([])
  const [prime, setPrime] = useState('')
  const [primeType, setPrimeType] = useState('')
  const [taux, setTaux] = useState('')
  const [potentialCandidate, setPotentialCandidate] = useState([])
  const [openCommandeModal, setOpenCommandeModal] = useState(true)
  const [selectedCommande, setSelectedCommande] = useState(null)
  const [isFixed, setIsFixed] = useState(false)
  const [selectedCandidate, setSelectedCandidate] = useState(null)
  const [isUpdate, setIsUpdate] = useState(false)
  const [recruiters, setRecruiters] = useState([])
  const [selectedClients, setSelectedClients] = useState([])
  const [selectedClient, setSelectedClient] = useState(null)
  const [timer, setTimer] = useState(null)
  const [isLoadingValidate, setIsLoadingValidate] = useState(false)

  const [facturationDescription, setFactureDescription] = useState('')
  const [facturationQuantity, setFactureQuantity] = useState('')
  const [facturationRate, setFactureRate] = useState('')

  const [documentCount, setDocumentCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [usedMotif, setUsedMotif] = useState([])

  const addFacturation = () => {
    if (!facturationDescription.length) return
    if (!facturationQuantity.length) return
    if (!facturationRate.length) return

    const facturations = order.facturation
      ? [
          ...order.facturation,
          {
            description: facturationDescription,
            quantity: facturationQuantity,
            rate: facturationRate,
          },
        ]
      : [
          {
            description: facturationDescription,
            quantity: facturationQuantity,
            rate: facturationRate,
          },
        ]
    setFactureDescription('')
    setFactureQuantity('')
    setFactureRate('')
    setOrder({ ...order, facturation: facturations })
  }

  const deleteOrder = (e, orderId) => {
    axiosInstance
      .delete(`order/${orderId}`)
      .then((res) => {
        const orderIdx = orders.findIndex((ord) => ord._id === orderId)
        const originalOrderIdx = originalOrders.findIndex(
          (ord) => ord._id === orderId,
        )
        const newOrders = [...orders]
        const newOriginalOrders = [...originalOrders]
        newOrders.splice(orderIdx, 1)
        newOriginalOrders.splice(originalOrderIdx)
        setOrders(newOrders)
        setOriginalOrders(newOriginalOrders)
        message.success('Commande supprimée !')
      })
      .catch((e) => {
        message.error("Une erreur s'est produite !")
        console.log(e)
      })
  }

  const onChangePage = (page, pageSize) => {
    setIsLoading(true)
    axiosInstance
      .get(`order/paginate/${page - 1}`)
      .then((res) => {
        setOrders(res.data)
        setFilter('Toutes')
        setIsLoading(false)
        setCurrentPage(page)
        console.log(res.data)
      })
      .catch((e) => {
        console.log(e)
        setIsLoading(false)
      })
  }




  const cacesData = cacesList.map((cace) => {
    return {
      title: cace,
      value: cace,
      key: cace,
    }
  })

  const onChangeCaces = (newValue) => {
    const newOrder = { ...order }
    newOrder.caceses = newValue
    setOrder(newOrder)
  }

  const cacesProps = {
    treeData: cacesData,
    onChange: onChangeCaces,
    value: order.caceses,
    treeCheckable: true,
    placeholder: 'Caces',
  }


  const onChangeHabilitation = (newValue) => {
    const newOrder = { ...order }
    newOrder.habilitations = newValue
    setOrder(newOrder)
  }
  const habilitationsData = habilitationsList.map((habilitation) => {
    return {
      title: habilitation,
      value: habilitation,
      key: habilitation,
    }
  })

  const habilitationsProps = {
    treeData: habilitationsData,
    onChange: onChangeHabilitation,
    value: order.habilitations,
    treeCheckable: true,
    placeholder: 'Habilitations',
  }

  const licencesArray = [
    'AM',
    'A1',
    'A2',
    'A',
    'B1',
    'B',
    'C1',
    'C',
    'D1',
    'D',
    'BE',
    'C1E',
    'CE',
    'D1E',
    'DE',
  ]

  const onChangeLicence = (newValue) => {
    const newOrder = { ...order }
    newOrder.licences = newValue
    setOrder(newOrder)
  }


  const licenceData = licencesArray.map((licence) => {
    return {
      title: licence,
      value: licence,
      key: licence,
    }
  })

  const licenceProps = {
    treeData: licenceData,
    onChange: onChangeLicence,
    value: order.licences,
    treeCheckable: true,
    placeholder: 'Permis',
  }

  const diplomasArray = [
    'Aide soignant',
    'infirmier',
    'AES',
    'AMP',
    'BTS analyse biologique',
    'BP préparateur en pharmacie',
    'CAP - BEP',
    'Baccalauréat',
    'DEUG - BTS - DUT - DEUST',
    'Licence - BUT',
    'Maîtrise - Master',
    'Doctorat',
  ]

  const onChangeDiploma = (newValue) => {
    const newOrder = { ...order }
    newOrder.diplomas = newValue
    setOrder(newOrder)
  }

  const diplomasData = diplomasArray.map((diploma) => {
    return {
      title: diploma,
      value: diploma,
      key: diploma,
    }
  })

  const diplomasProps = {
    treeData: diplomasData,
    onChange: onChangeDiploma,
    value: order.diplomas,
    treeCheckable: true,
    placeholder: 'Diplome',
  }

  const deleteFacturationItem = (idx) => {
    const facturations = order.facturation.filter(
      (fact, index) => idx !== index,
    )
    setOrder({ ...order, facturation: facturations })
  }

  const filterOrders = (status) => {
    if (status === 'Toutes') {
      setOrders(originalOrders)
      setFilter('Toutes')
      setIsOriginal(true)
      return
    }
    setIsOriginal(false)

    axiosInstance
      .get(`order/filter/${status}`)
      .then((res) => {
        setOrders(res.data)
      })
      .catch((e) => {
        message.error("Une erreur s'est produite !")
        console.log(e)
      })
  }

  const navigate = useNavigate()

  const images = [
    { selected: epiaudioselected, name: epiaudio, description: 'audio' },
    { selected: epicasqueselected, name: epicasque, description: 'casque' },
    {
      selected: epichaussureselected,
      name: epichaussure,
      description: 'chaussure',
    },
    { selected: epigiletselected, name: epigilet, description: 'gilet' },
    { selected: epimasqueselected, name: epimasque, description: 'masque' },
    { selected: epibleuselected, name: epibleu, description: 'bleu' },
    { selected: epigantselected, name: epigant, description: 'gant' },
    { selected: epiharnaisselected, name: epiharnais, description: 'harnais' },
    { selected: epilunetteselected, name: epilunette, description: 'lunette' },
  ]

  /* const usedMotifArray = usedMotif.map((motif, idx) => {
    return { value: motif, label: motif, key: idx }
  }) */
  /* const otherMotifArray = otherMotif.map((motif, idx) => {
    return { value: motif, label: motif, key: idx }
  }) */
  const qualificationsArray = qualifications.map((qualification, idx) => {
    return { value: qualification, label: qualification, key: idx }
  })

  const searchClient = (word) => {
    if (word.length === 0) {
      setSelectedClients([])
    }
    if (word.length < 3) {
      if (timer) clearTimeout(timer)
    }
    if (word.length >= 3) {
      if (timer) clearTimeout(timer)

      const timer2 = setTimeout(() => {
        axiosInstance
          .post('client/find-by-text', { query: word })
          .then((res) => {
            setSelectedClients(res.data)
            console.log(res.data)
          })
          .catch((e) => console.log(e))
      }, 700)
      setTimer(timer2)
    }
  }

  const loadRecours = () => {
    axiosInstance
      .get('pld-contract/recours')
      .then((res) => {
        console.log(res.data)
        setUsedMotif(
          res.data
            .map((moti) => moti.IdReferentiel + ' : ' + moti.Designation)
            .map((motif, idx) => {
              return { value: motif, label: motif, key: idx }
            }),
        )
      })
      .catch((e) => console.log(e))
  }

  const search = (word) => {
    if (word.length === 0) {
      setOrders(originalOrders)
    }
    if (word.length >= 3) {
      axiosInstance
        .post('order/find-by-text', { query: word })
        .then((res) => setOrders(res.data))
    }
  }

  const loadRecruiters = () => {
    axiosInstance
      .get('user')
      .then((res) => {
        const recruitersArray = res.data.map((recruiter, idx) => {
          return {
            value: recruiter._id,
            label:
              recruiter.firstname
                .toLowerCase()
                .split('')
                .map((char, index) => (index === 0 ? char.toUpperCase() : char))
                .join('') +
              ' ' +
              recruiter.lastname
                .toLowerCase()
                .split('')
                .map((char, index) => (index === 0 ? char.toUpperCase() : char))
                .join(''),
            key: idx,
          }
        })
        setRecruiters(recruitersArray)
      })
      .catch((e) => {
        if (e.response.data && e.response.data.statusCode === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }

  useEffect(() => {
    loadRecruiters()
    loadRecours()

    axiosInstance
      .get('order/count')
      .then((res) => {
        console.log(documentCount)
        setDocumentCount(res.data)
        console.log(res.data)
      })
      .catch((e) => {})

    if (filter === 'En cours') {
      filterOrders('En cours')
    }

    axiosInstance
      .get('order')
      .then((res) => {
        const ordersData = res.data.map((order) => {
          return { ...order, loading: false }
        })
        if (filter === 'Toutes') setOrders(ordersData)
        setOriginalOrders(ordersData)
      })
      .catch((e) => {
        if (e.response.data && e.response.data.statusCode === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }, [])

  const selectContact = (e) => {
    const newOrder = { ...order }
    newOrder.contact = e
    setOrder(newOrder)
  }

  const selectQualification = (e) => {
    const newOrder = { ...order }
    newOrder.qualification = e
    setOrder(newOrder)
  }

  const selectTypeContrat = (e) => {
    const newOrder = { ...order }
    newOrder.typeContrat = e
    setOrder(newOrder)
  }

  const selectMotif = (e) => {
    const newOrder = { ...order }
    newOrder.motif = e
    setOrder(newOrder)
  }

  /* const selectOtherMotif = (e) => {
    const newOrder = { ...order }
    newOrder.motif = null
    newOrder.otherMotif = e
    setOrder(newOrder)
  } */

  const selectJustificatif = (e) => {
    const newOrder = { ...order }
    newOrder.justificatif = e
    setOrder(newOrder)
  }

  const selectNombreHeureHebdo = (e) => {
    const newOrder = { ...order }
    newOrder.nbHeureHebdo = e
    setOrder(newOrder)
    console.log(newOrder)
  }

  const selectDateDebut = (e) => {
    console.log(e.getDate())
    const newOrder = { ...order }
    newOrder.startDate = e
    setOrder(newOrder)
  }

  const selectDateFin = (e) => {
    const newOrder = { ...order }
    newOrder.endDate = e
    setOrder(newOrder)
  }

  const selectClientName = (e) => {
    const newOrder = { ...order }
    newOrder.clientName = e
    setOrder(newOrder)
  }

  const selectAddress = (e) => {
    const newOrder = { ...order }
    newOrder.address = e
    setOrder(newOrder)
  }

  const selectIsVehicule = (bool) => {
    const newOrder = { ...order }
    newOrder.isVehicule = bool
    setOrder(newOrder)
  }

  const selectConsigne = (e) => {
    const newOrder = { ...order }
    newOrder.consigne = e
    setOrder(newOrder)
  }

  const selectPostCode = (e) => {
    const newOrder = { ...order }
    newOrder.postCode = e
    setOrder(newOrder)
  }

  const selectTown = (e) => {
    const newOrder = { ...order }
    newOrder.town = e
    setOrder(newOrder)
  }

  const setTask = (e) => {
    const newOrder = { ...order }
    newOrder.task = e
    setOrder(newOrder)
  }

  const selectQuantity = (e) => {
    const newOrder = { ...order }
    if (e <= 0) e = 0
    newOrder.quantity = e
    setOrder(newOrder)
  }

  const selectHourlyRate = (e) => {
    const newOrder = { ...order }
    newOrder.hourlyRate = e
    setOrder(newOrder)
  }

  const selectEpiProvider = (e) => {
    const newOrder = { ...order }
    newOrder.epiProvider = e
    setOrder(newOrder)
  }

  const deletePrime = (idx) => {
    const newPrimes = order.primes.filter((prime, index) => idx !== index)
    setOrder({
      ...order,
      primes: newPrimes,
    })
  }

  const formatJsDate = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${day}/${month}/${year}`
  }

  const showCommande = (commande, idx) => {
    const newOrders = [...orders]
    newOrders[idx].loading = true
    setOrders(newOrders)
    console.log("idididdiidi")
    axiosInstance
      .get(`/order/${commande._id}`)
      .then((res) => {
        const newCommande = res.data.order
        console.log(res.data.potentialCandidates);
        newCommande.candidates = res.data.potentialCandidates.filter(
          (candidate) => {
            return (
              !newCommande.contactedCandidates.some(
                (c) => c._id === candidate._id,
              ) &&
              !newCommande.refusedByAgence.some(
                (c) => c._id === candidate._id,
              ) &&
              !newCommande.acceptedCandidates.some(
                (c) => c._id === candidate._id,
              ) &&
              !newCommande.confirmedCandidates.some(
                (c) => c._id === candidate._id,
              ) &&
              !newCommande.submittedToClient.some(
                (c) => c._id === candidate._id,
              ) &&
              !newCommande.contractedCandidates.some(
                (c) => c._id === candidate._id,
              ) &&
              !newCommande.refusedCandidates.some(
                (c) => c._id === candidate._id,
              )
            )
          },
        )
        let prefixCommande = commande.postCode.substring(0, 2)

        console.log(newCommande.candidates);

        let candidatsFiltres = newCommande.candidates.filter((candidat) =>
          candidat.locations.some(
            (location) => location.substring(0, 2) === prefixCommande,
          ),
        )

        console.log(candidatsFiltres);


        newCommande.candidates = candidatsFiltres
        const contact = newCommande.contact.split(' ')
        const phone = contact.pop()
        const email = contact.pop()
        const name = contact.join(' ')
        setSelectedCommande({
          ...newCommande,
          contactData: { email, phone, name },
        })
        setOpenCommandeModal(true)

        newOrders[idx].loading = false
        setOrders(newOrders)
      })
      .catch((e) => console.log(e))
  }

  const formaterHeure = (date) => {
    let heures = date.getHours()
    let minutes = date.getMinutes()

    // Ajouter un zéro devant les nombres inférieurs à 10
    heures = heures < 10 ? '0' + heures : heures
    minutes = minutes < 10 ? '0' + minutes : minutes

    return `${heures}:${minutes}`
  }

  const validateOrder = () => {
    setIsLoadingValidate(true)
    let newOrder
    if (isUpdate) {
      newOrder = {
        ...order,
        startHour: formaterHeure(order.startHour),
        endHour: formaterHeure(order.endHour),
      }
      axiosInstance
        .put(`/order/${order._id}`, { ...newOrder, client: order.client._id })
        .then((res) => {
          const newCommande = res.data.order
          newCommande.candidates = res.data.potentialCandidates
          const contact = newCommande.contact.split(' ')
          const phone = contact.pop()
          const email = contact.pop()
          const name = contact.join(' ')
          const newOrders = [{ ...newCommande, loading: false }, ...orders]
          setOrders(newOrders)
          setOrder({})
          setStep('1')
          setSelectedCommande({
            ...newCommande,
            contactData: { email, phone, name },
          })
          setOpenModal(false)
          setOpenCommandeModal(true)
          setIsUpdate(false)
        })
        .catch((e) => {
          console.log(e)
          if (e.response.data.statusCode === 403) {
            localStorage.clear()
            navigate('/login')
          }
        })
        .finally(() => setIsLoadingValidate(false))
    } else {
      newOrder = {
        ...order,
        startHour: formaterHeure(order.startHour),
        endHour: formaterHeure(order.endHour),
      }
      axiosInstance
        .post('order', {
          ...newOrder,
          companyName: order.client.companyName,
          client: order.client._id,
          motif: order.motif,
        })
        .then((res) => {
          const newCommande = res.data.order
          newCommande.candidates = res.data.potentialCandidates
          const contact = newCommande.contact.split(' ')
          const phone = contact.pop()
          const email = contact.pop()
          const name = contact.join(' ')
          const newOrders = [{ ...newCommande, loading: false }, ...orders]
          setOrders(newOrders)
          setOrder({})
          setStep('1')
          setSelectedCommande({
            ...newCommande,
            contactData: { email, phone, name },
          })
          setOpenModal(false)
          setOpenCommandeModal(true)
          setIsUpdate(false)
        })
        .catch((e) => {
          console.log(e)
          if (e.response.data.statusCode === 403) {
            localStorage.clear()
            navigate('/login')
          }
        })
        .finally(() => setIsLoadingValidate(false))
    }
  }

  const deleteEpi = () => {
    const newOrder = { ...order }
    newOrder.epis = []
    console.log(newOrder)
    setOrder(newOrder)
  }

  const addPrime = () => {
    const newPrime = {
      prime,
      primeType,
      taux,
    }
    const primes = order.primes ? [...order.primes, newPrime] : [newPrime]
    setOrder({
      ...order,
      primes,
    })

    setPrime('')
    setPrimeType('')
    setTaux('')
  }

  const addEpi = (image) => {
    const epis = order.epis ? order.epis : []
    if (!epis.includes(image.description)) epis.push(image.description)
    else {
      const index = epis.findIndex((item) => _.isEqual(item, image.description))

      if (index !== -1) {
        epis.splice(index, 1)
      }
    }
    const newOrder = { ...order, epis }
    setOrder(newOrder)
    console.log(newOrder)
  }

  const selectClient = (e) => {
    const client = e
    const newOrder = order
    newOrder.client = client
    setOrder(newOrder)
    const clientAdresses = [
      ` ${client.address} ${client.postCode} ${client.town} `.toUpperCase(),
      `Adresse de livraison : ${client.addressLivraison} ${client.postCodeLivraison} ${client.townLivraison} `.toUpperCase(),
      `Adresse de facturation : ${client.addressFacturation} ${client.postCodeFacturation} ${client.townFacturation} `.toUpperCase(),
    ]

    const clientContactsArray = client.contacts.map((contact, idx) => {
      return {
        value: `${contact.firstname} ${contact.lastname} ${contact.email} ${contact.mobile}`,
        label: `${contact.firstname} ${contact.lastname} ${contact.email} ${contact.mobile}`,
        key: idx,
      }
    })

    const clientAddressesArray = clientAdresses.map((address, idx) => {
      return {
        value: address,
        label: address,
        key: idx,
      }
    })
    setClientAdressesArray(clientAddressesArray)
    setClientContactsArray(clientContactsArray)
    setSelectedClient(e)
  }

  const selectStep = (step, direction) => {
    if (step === '4' && order.typeContrat !== 'Interim' && direction === 'f')
      step = '6'

    if (step === '5' && order.typeContrat !== 'Interim' && direction === 'b')
      step = '3'
    if (
      step === '5' &&
      (order.typeContrat === 'CDI' || order.typeContrat === 'CDD') &&
      direction === 'b'
    )
      step = '3'

    if (step === '12' && order.typeContrat !== 'Interim' && direction === 'f')
      step = '13'

    if (step === '12' && order.typeContrat !== 'Interim' && direction === 'b')
      step = '11'

    if (step === '17' && !order.epis.length) {
      validateOrder()
    }
    setStep(step)
  }

  /*   const handleDrag = (e, ui) => {
    e.stopPropagation()

    const id = ui.node.id
    // Get the position of the draggable element
    const elementX = e.srcElement.getBoundingClientRect().x
    const elementY = e.srcElement.getBoundingClientRect().y
    // Get the position and dimensions of the other div
    const otherDiv = document.getElementById('contactedCandidat')
    const { x, y, left, top, right, bottom } = otherDiv.getBoundingClientRect()

    // Check if the draggable element is inside the other div
    if (
      elementX > left &&
      elementX < right &&
      elementY > top &&
      elementY < bottom
    ) {
      let availableCandidat = selectedCommande.candidates
      let candidate = availableCandidat.find((candidat) => candidat._id === id)
      availableCandidat = availableCandidat.filter(
        (candidat) => candidat._id !== id,
      )
      axiosInstance
        .put(`/order/${selectedCommande._id}`, {
          contactedCandidates: [
            ...selectedCommande.contactedCandidates.map((c) => c._id),
            candidate._id,
          ],
        })
        .then((res) => {
          console.log(res.data)
          message.success('Modifications enregistrées')
          setSelectedCommande({
            ...selectedCommande,
            candidates: availableCandidat,
            contactedCandidates: [
              ...selectedCommande.contactedCandidates,
              candidate,
            ],
          })
        })
        .catch((e) => console.log(e))
    }
  } */

  /*   const handleDragContacted = (e, ui) => {
    const id = ui.node.id
    // Get the position of the draggable element
    const elementX = e.srcElement.getBoundingClientRect().x
    const elementY = e.srcElement.getBoundingClientRect().y
    // Get the position and dimensions of the other div
    const otherDiv = document.getElementById('proposedCandidat')
    const { x, y, left, top, right, bottom } = otherDiv.getBoundingClientRect()

    // Check if the draggable element is inside the other div
    if (
      elementX > left &&
      elementX < right &&
      elementY > top &&
      elementY < bottom
    ) {
      let contactedCandidates = selectedCommande.contactedCandidates
      let candidate = contactedCandidates.find(
        (candidat) => candidat._id === id,
      )
      contactedCandidates = contactedCandidates.filter(
        (candidat) => candidat._id !== id,
      )
      axiosInstance
        .put(`/order/${selectedCommande._id}`, {
          contactedCandidates,
          submittedToClient: [
            ...selectedCommande.submittedToClient.map((c) => c._id),
            candidate._id,
          ],
        })
        .then((res) => {
          console.log(res.data)
          message.success('Modifications enregistrées')
          setSelectedCommande({
            ...selectedCommande,
            contactedCandidates,
            submittedToClient: [
              ...selectedCommande.submittedToClient,
              candidate,
            ],
          })
        })
        .catch((e) => console.log(e))
    }
  }
 */

  const selectReason = (e) => {
    console.log(e)
  }

  const selectRecruiter = (e) => {
    const newOrder = { ...order }
    newOrder.recruiter = e
    setOrder(newOrder)
  }

  const setAdressFromSiege = (client) => {
    const newOrder = { ...order }
    newOrder.clientName = client.companyName
    newOrder.address = client.address
    newOrder.postCode = client.postCode
    newOrder.town = client.town
    setOrder(newOrder)
  }

  const setAdressFromLivraison = (client) => {
    const newOrder = { ...order }
    newOrder.clientName = client.companyName
    newOrder.address = client.addressLivraison
    newOrder.postCode = client.postCodeLivraison
    newOrder.town = client.townLivraison
    setOrder(newOrder)
  }

  const setAdressFromFacturation = (client) => {
    const newOrder = { ...order }
    newOrder.clientName = client.companyName
    newOrder.address = client.addressFacturation
    newOrder.postCode = client.postCodeFacturation
    newOrder.town = client.townFacturation
    setOrder(newOrder)
  }

  const addWorkedDay = (day) => {
    let days = order.workedDays
    if (days.includes(day)) {
      console.log(days, '1')
      days = days.filter((d) => d !== day)
      console.log(days, '2')
    } else {
      days.push(day)
    }
    setOrder({ ...order, workedDays: days })
  }

  const clientArray = clients.map((client) => {
    return {
      value: client._id,
      label: `${client.companyName} - ${client.address} ${client.postCode} ${client.town}`,
    }
  })

  return (
    <div className="">
      <FilterOrder
        open={isFilterOrder}
        originalOrders={originalOrders}
        setOrders={setOrders}
        setIsOpen={setIsFilterOrder}
      />
      {selectedCommande && (
        <div>
          <ModalDetail
            order={order}
            setOrder={setOrder}
            setOpenModal={setOpenModal}
            openCommandeModal={openCommandeModal}
            setOpenCommandeModal={setOpenCommandeModal}
            selectedCommande={selectedCommande}
            setSelectedCommande={setSelectedCommande}
          />
        </div>
      )}

      {selectedCandidate && (
        <OrderCandidatModal
          setSelectedCandidate={setSelectedCandidate}
          candidate={selectedCandidate}
        />
      )}
      <Modal
        width={1000}
        open={openModal}
        onCancel={() => {
          setOpenModal(false)
          if (step === '18') setStep('1')
        }}
        footer={null}
      >
        <div className="h-[600px]">
          {isLoadingValidate && (
            <div className="w-full h-screen  absolute top-0 z-30 right-0 bg-gray-400 bg-opacity-40 flex items-center justify-center">
              <Puff
                height="80"
                width="80"
                radius={1}
                color="#4fa94d"
                ariaLabel="puff-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          )}
          {step === '1' && (
            <div className="flex flex-col h-full justify-between">
              <div>
                <p className="font-semibold  text-center text-3xl">Client</p>

                <Input
                  className="outline-none text-gray-600 font-light rounded-md placeholder-gray-400"
                  placeholder="Client"
                  onChange={(e) => searchClient(e.target.value)}
                />
                {selectedClients.length > 0 && (
                  <div className="border flex flex-col space-y-2 border-gray-300 rounded-md mt-4 p-2">
                    {selectedClients.map((client) => {
                      return (
                        <p
                          onClick={() => selectClient(client)}
                          className={`border-b flex items-center space-x-1  border-b-gray-300 pb-2 ${
                            selectedClient?._id === client._id
                              ? 'bg-green-400'
                              : ''
                          }`}
                        >
                          <span>{client.companyName}</span>
                        </p>
                      )
                    })}
                  </div>
                )}
              </div>

              {/*  <div>
                <p className="font-semibold  text-center text-3xl">Client</p>

                <Select
                  className="w-full py-6 my-2 ring-0"
                  showSearch="true"
                  onChange={(e) => selectClient(e)}
                  value={
                    order.client
                      ? `${order.client?.companyName} - ${order.client?.address} ${order.client?.postCode} ${order.client?.town}`
                      : null
                  }
                  optionFilterProp="children"
                  placeholder="Client"
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={clientArray}
                />
              </div> */}

              <div className="flex items-center justify-center ">
                <button
                  disabled={!order.client}
                  onClick={() => selectStep('2', 'f')}
                  className={`${
                    !order.client
                      ? 'bg-gray-200 text-gray-600'
                      : 'bg-mountain-500 text-white'
                  } rounded-md p-4 `}
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === '2' && (
            <div className="flex flex-col h-full justify-between">
              <div>
                <p className="font-semibold text-center text-3xl">Contact</p>

                <Select
                  className="w-full py-6 my-2 ring-0"
                  onChange={(e) => selectContact(e)}
                  value={order.contact ? `${order.contact}` : null}
                  optionFilterProp="children"
                  placeholder="Contact"
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={clientContactsArray}
                />
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep('1', 'b')}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep('3', 'f')}
                  className={`${
                    !order.contact
                      ? 'bg-gray-200 text-gray-600'
                      : 'bg-mountain-500 text-white'
                  } rounded-md p-4 `}
                  disabled={!order.contact}
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === '3' && (
            <div className="flex flex-col h-full justify-between">
              <div>
                <p className="font-semibold text-center text-3xl">
                  Type de contrat
                </p>

                <Select
                  className="w-full py-6 my-2"
                  showSearch
                  onChange={(e) => selectTypeContrat(e)}
                  value={order.typeContrat ? `${order.typeContrat}` : null}
                  optionFilterProp="children"
                  placeholder="Type de contrat"
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    { value: 'Interim', label: 'Interim', key: 'Interim' },
                    { value: 'Vacation', label: 'Vacation', key: 'Vacation' },
                    { value: 'CDD', label: 'CDD', key: 'CDD' },
                    { value: 'CDI', label: 'CDI', key: 'CDI' },
                  ]}
                />
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep('2', 'b')}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep('4', 'f')}
                  className={`${
                    !order.typeContrat
                      ? 'bg-gray-200 text-gray-600'
                      : 'bg-mountain-500 text-white'
                  } rounded-md p-4 `}
                  disabled={!order.typeContrat}
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === '4' && (
            <div className="flex flex-col h-full justify-between">
              <div>
                <p className="font-semibold text-center text-3xl">
                  Motif de recours
                </p>

                <div>
                  <p>Les motifs de recours</p>
                  <Select
                    className="w-full py-3 my-2"
                    showSearch
                    onChange={(e) => selectMotif(e)}
                    value={order.motif ? `${order.motif}` : null}
                    optionFilterProp="children"
                    placeholder="Motif de recours"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={usedMotif}
                  />
                </div>

                {/* <div>
                  <p>Les autres motifs</p>
                  <Select
                    className="w-full py-3 my-2"
                    showSearch
                    onChange={(e) => selectOtherMotif(e)}
                    value={order.otherMotif ? `${order.otherMotif}` : null}
                    optionFilterProp="children"
                    placeholder="Motif de recours"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={otherMotifArray}
                  />
                </div> */}
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep('3', 'b')}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep('5', 'f')}
                  className={`${
                    !order.motif
                      ? 'bg-gray-200 text-gray-600'
                      : 'bg-mountain-500 text-white'
                  } rounded-md p-4 `}
                  disabled={!order.motif}
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === '5' && (
            <div className="flex flex-col h-full justify-between">
              <div>
                <p className="font-semibold text-center text-3xl">
                  Développer le motif
                </p>

                <div>
                  <p className="mt-6 text-lg"> {order.motif} </p>
                  <Input
                    onChange={(e) => selectJustificatif(e.target.value)}
                    value={order.justificatif ? `${order.justificatif}` : null}
                    className="rounded-md border border-gray-300 mb-2 mt-6"
                    placeholder="Justificatif"
                  />
                </div>
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep('4', 'b')}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep('6', 'f')}
                  className={`${
                    !order.justificatif
                      ? 'bg-gray-200 text-gray-600'
                      : 'bg-mountain-500 text-white'
                  } rounded-md p-4 `}
                  disabled={!order.justificatif}
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === '6' && (
            <div className="flex flex-col h-full justify-between">
              <div>
                {order.typeContrat !== 'Vacation' && (
                  <p className="font-semibold text-center text-3xl">
                    Date de la mission
                  </p>
                )}

                {order.typeContrat === 'Vacation' && (
                  <p className="font-semibold text-center text-3xl">
                    Date de la vacation
                  </p>
                )}

                <div className="flex items-center my-6 space-x-2 justify-center">
                  <DatePicker
                    onChange={(e) => selectDateDebut(e.$d)}
                    value={order.startDate ? dayjs(order?.startDate) : null}
                    className="w-1/2 h-10"
                    format="DD/MM/YYYY"
                    placeholder="Date de début"
                  />
                  {order.typeContrat !== 'CDI' && (
                    <DatePicker
                      onChange={(e) => selectDateFin(e.$d)}
                      value={order.endDate ? dayjs(order?.endDate) : null}
                      className="w-1/2 h-10"
                      format="DD/MM/YYYY"
                      placeholder="Date de fin"
                    />
                  )}
                </div>
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep('5', 'b')}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                {order.typeContrat !== 'CDI' && (
                  <button
                    onClick={() => selectStep('7', 'f')}
                    className={`${
                      !order.startDate || !order.endDate
                        ? 'bg-gray-200 text-gray-600'
                        : 'bg-mountain-500 text-white'
                    } rounded-md p-4 `}
                    disabled={!order.startDate || !order.endDate}
                  >
                    Suivant
                  </button>
                )}

                {order.typeContrat === 'CDI' && (
                  <button
                    onClick={() => selectStep('7', 'f')}
                    className={`${
                      !order.startDate
                        ? 'bg-gray-200 text-gray-600'
                        : 'bg-mountain-500 text-white'
                    } rounded-md p-4 `}
                    disabled={!order.startDate}
                  >
                    Suivant
                  </button>
                )}
              </div>
            </div>
          )}

          {step === '7' && (
            <div className="flex flex-col h-full justify-between">
              <div>
                <p className="font-semibold text-center text-3xl">
                  Horaire de la mission
                </p>

                <div className="flex items-center my-6 space-x-2">
                  <DesktopTimePicker
                    value={order.startHour ? `${order.startHour}` : null}
                    onChange={(newValue) =>
                      setOrder({ ...order, startHour: newValue.$d })
                    }
                    format="HH:mm"
                    className="w-1/2 outline-none"
                    placeholder="Heure de début"
                  />
                  <DesktopTimePicker
                    format="HH:mm"
                    value={order.endHour ? `${order.endHour}` : null}
                    className="w-1/2 outline-none focus:outline-none hover:outline-none"
                    onChange={(newValue) => {
                      setOrder({ ...order, endHour: newValue.$d })
                    }}
                    placeholder="Heure de fin"
                  />
                </div>
                <div>
                  <p className="font-semibold text-center text-3xl">
                    Jours travaillés
                  </p>
                  <div className="flex items-center justify-around space-x-1 mt-6">
                    <p
                      onClick={() => addWorkedDay('Lundi')}
                      className={`p-2 cursor-pointer rounded-md ${
                        order.workedDays.includes('Lundi')
                          ? 'bg-green-400 text-white'
                          : 'border border-gray-500'
                      }`}
                    >
                      Lundi
                    </p>
                    <p
                      onClick={() => addWorkedDay('Mardi')}
                      className={`p-2 cursor-pointer rounded-md ${
                        order.workedDays.includes('Mardi')
                          ? 'bg-green-400 text-white'
                          : 'border border-gray-500'
                      }`}
                    >
                      Mardi
                    </p>
                    <p
                      onClick={() => addWorkedDay('Mercredi')}
                      className={`p-2 cursor-pointer rounded-md ${
                        order.workedDays.includes('Mercredi')
                          ? 'bg-green-400 text-white'
                          : 'border border-gray-500'
                      }`}
                    >
                      Mercredi
                    </p>
                    <p
                      onClick={() => addWorkedDay('Jeudi')}
                      className={`p-2 cursor-pointer rounded-md ${
                        order.workedDays.includes('Jeudi')
                          ? 'bg-green-400 text-white'
                          : 'border border-gray-500'
                      }`}
                    >
                      Jeudi
                    </p>
                    <p
                      onClick={() => addWorkedDay('Vendredi')}
                      className={`p-2 cursor-pointer rounded-md ${
                        order.workedDays.includes('Vendredi')
                          ? 'bg-green-400 text-white'
                          : 'border border-gray-500'
                      }`}
                    >
                      Vendredi
                    </p>
                    <p
                      onClick={() => addWorkedDay('Samedi')}
                      className={`p-2 cursor-pointer rounded-md ${
                        order.workedDays.includes('Samedi')
                          ? 'bg-green-400 text-white'
                          : 'border border-gray-500'
                      }`}
                    >
                      Samedi
                    </p>
                    <p
                      onClick={() => addWorkedDay('Dimanche')}
                      className={`p-2 cursor-pointer rounded-md ${
                        order.workedDays.includes('Dimanche')
                          ? 'bg-green-400 text-white'
                          : 'border border-gray-500'
                      }`}
                    >
                      Dimanche
                    </p>
                  </div>

                  <div>
                    <Input
                      onChange={(e) => selectNombreHeureHebdo(e.target.value)}
                      value={
                        order.nbHeureHebdo ? `${order.nbHeureHebdo}` : null
                      }
                      className="rounded-md border border-gray-300 mb-2 mt-6"
                      placeholder="Nombre heures hebdomadaire"
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep('6', 'b')}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep('8', 'f')}
                  disabled={!order.startHour || !order.endHour}
                  className={`${
                    !order.startHour || !order.endHour
                      ? 'bg-gray-200 text-gray-600'
                      : 'bg-mountain-500 text-white'
                  } rounded-md p-4 `}
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === '8' && (
            <div className="flex flex-col h-full justify-between">
              <div>
                <p className="font-semibold text-center text-3xl">
                  Lieu de la mission
                </p>
                <div className="flex space-x-2 items-center justify-around mt-6">
                  <p
                    onClick={() => setAdressFromSiege(order.client)}
                    className="text-xs hover:cursor-pointer text-blue-500
                "
                  >
                    Recopier l'adresse du siège
                  </p>
                  <p
                    onClick={() => setAdressFromLivraison(order.client)}
                    className="text-xs hover:cursor-pointer text-blue-500
                "
                  >
                    Recopier l'adresse de livraison
                  </p>
                  <p
                    onClick={() => setAdressFromFacturation(order.client)}
                    className="text-xs hover:cursor-pointer text-blue-500"
                  >
                    Recopier l'adresse de facturation
                  </p>
                </div>
                <div className="flex flex-col items-center my-6 space-y-3">
                  <Input
                    onChange={(e) => selectClientName(e.target.value)}
                    value={order.clientName ? `${order.clientName}` : null}
                    className="rounded-md border border-gray-300 mb-2"
                    placeholder="Nom du client final"
                  />
                  <Input
                    onChange={(e) => selectAddress(e.target.value)}
                    value={order.address ? `${order.address}` : null}
                    className="rounded-md border border-gray-300 mb-2"
                    placeholder="Adresse"
                  />
                  <Input
                    onChange={(e) => selectPostCode(e.target.value)}
                    value={order.postCode ? `${order.postCode}` : null}
                    className="rounded-md border border-gray-300 mb-2"
                    placeholder="Code postal"
                  />
                  <Input
                    onChange={(e) => selectTown(e.target.value)}
                    value={order.town ? `${order.town}` : null}
                    className="rounded-md border border-gray-300 mb-2"
                    placeholder="Ville"
                  />
                </div>
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep('7', 'b')}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep('9', 'f')}
                  className={`${
                    !order.clientName ||
                    !order.address ||
                    !order.postCode ||
                    !order.town
                      ? 'bg-gray-200 text-gray-600'
                      : 'bg-mountain-500 text-white'
                  } rounded-md p-4 `}
                  disabled={
                    !order.clientName ||
                    !order.address ||
                    !order.postCode ||
                    !order.town
                  }
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === '9' && (
            <div className="flex flex-col h-full justify-between">
              <div>
                <p className="font-semibold text-center text-3xl">Poste</p>

                <div className="flex flex-col items-center my-6 space-y-3">
                  <Select
                    className="w-full py-6 my-2"
                    showSearch
                    onChange={(e) => selectQualification(e)}
                    value={
                      order.qualification ? `${order.qualification}` : null
                    }
                    optionFilterProp="children"
                    placeholder="Poste"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={qualificationsArray}
                  />
                  <p>Quantite</p>
                  <p className="flex items-center space-x-4">
                    <RemoveCircleOutlineRounded
                      onClick={() => selectQuantity(order.quantity - 1)}
                    />{' '}
                    <span className="text-xl">{order.quantity}</span>
                    <AddCircleOutlineIcon
                      onClick={() => selectQuantity(order.quantity + 1)}
                    />{' '}
                  </p>
                </div>
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep('8', 'b')}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep('10', 'f')}
                  className={`${
                    !order.qualification
                      ? 'bg-gray-200 text-gray-600'
                      : 'bg-mountain-500 text-white'
                  } rounded-md p-4 `}
                  disabled={!order.qualification}
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === '10' && (
            <div className="flex flex-col h-full justify-between">
              <div>
                <p className="font-semibold text-center text-3xl">
                  Renseigner le taux horaire
                </p>

                <div className="flex flex-col items-center my-6 space-y-3">
                  <Input
                    onChange={(e) => selectHourlyRate(e.target.value)}
                    value={order.hourlyRate ? `${order.hourlyRate}` : null}
                    className="rounded-md border border-gray-300 mb-2"
                    placeholder="Taux horaire"
                  />
                </div>
              </div>
              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep('9', 'b')}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep('11', 'f')}
                  className={`${
                    !order.hourlyRate
                      ? 'bg-gray-200 text-gray-600'
                      : 'bg-mountain-500 text-white'
                  } rounded-md p-4 `}
                  disabled={!order.hourlyRate}
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === '11' && (
            <div className="flex flex-col h-full justify-between ">
              <div>
                <p className="font-semibold mb-6 text-center text-3xl">
                  Renseigner primes/indemnités
                </p>

                <div className="flex items-center space-x-4 mb-4">
                  <div className="w-1/2 flex flex-col space-y-1">
                    <Select
                      className="w-full"
                      onChange={(e) => setPrime(e)}
                      value={prime}
                      optionFilterProp="children"
                      placeholder="Primes"
                      filterOption={(input, option) =>
                        (option?.label ?? '')
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={[
                        { value: 'Primes', label: 'Primes', key: 'Primes' },
                        {
                          value: 'Indemnités',
                          label: 'Indemnités',
                          key: 'Indemnités',
                        },
                      ]}
                    />

                    <Input
                      onChange={(e) => setPrimeType(e.target.value)}
                      value={primeType}
                      className="rounded-md border border-gray-300 mb-2"
                      placeholder="Type de prime"
                    />

                    <Input
                      onChange={(e) => setTaux(e.target.value)}
                      value={taux}
                      className="rounded-md border border-gray-300 mb-2"
                      placeholder="Taux ou montant"
                    />
                  </div>

                  <div className=" w-1/2 flex items-center justify-start">
                    <button
                      onClick={addPrime}
                      className="my-2 rounded-md  bg-mountain-500 p-2 text-white"
                    >
                      <ShoppingCartCheckoutIcon />
                    </button>
                  </div>
                </div>

                <div>
                  <div className="flex items-center space-x-2 uppercase my-4">
                    <p className="w-1/4 text-center">Prime</p>
                    <p className="w-1/4 text-center">Type de prime</p>
                    <p className="w-1/4 text-center">Taux ou montant </p>
                    <p className="w-1/4 text-center"></p>
                  </div>

                  {order.primes.map((prime, idx) => {
                    return (
                      <div key={idx} className="flex items-center space-x-2">
                        <p className="w-1/4 text-center">{prime.prime}</p>
                        <p className="w-1/4 text-center">{prime.primeType}</p>
                        <p className="w-1/4 text-center">{prime.taux}</p>
                        <p
                          onClick={() => deletePrime(idx)}
                          className="w-1/4 text-center"
                        >
                          <DeleteForeverOutlined />
                        </p>
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep('10', 'b')}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep('12', 'f')}
                  className="bg-mountain-500 rounded-md p-4 text-white"
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === '12' && (
            <div className="flex flex-col h-full justify-between">
              <div>
                <p className="font-semibold text-center text-3xl">
                  Consignes particulières
                </p>

                <div className="flex items-center my-6">
                  <Select
                    className="w-full py-6 my-2"
                    showSearch
                    optionFilterProp="children"
                    onChange={(e) => selectConsigne(e)}
                    value={order.consigne ? order.consigne : null}
                    placeholder="Consignes"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      {
                        value: '0 : Aucune',
                        label: '0 : Aucune',
                        key: '0',
                      },
                      {
                        value:
                          "1 : Port des EPI obligatoire. Le respect des règles de sécurité et d'hygiène est obligatoire.",
                        label:
                          "1 : Port des EPI obligatoire. Le respect des règles de sécurité et d'hygiène est obligatoire.",
                        key: '1',
                      },
                      {
                        value:
                          "2 : La formation sécurité est dispensée par le responsable en poste le jour de l'intégration.",
                        label:
                          "2 : La formation sécurité est dispensée par le responsable en poste le jour de l'intégration.",
                        key: '2',
                      },
                      {
                        value:
                          "3 : Port des EPI obligatoire. Le respect des règles de sécurité et d'hygiène est obligatoire. La formation sécurité est dispensée par le responsable en poste le jour de l'intégration.",
                        label:
                          "3 :  Port des EPI obligatoire. Le respect des règles de sécurité et d'hygiène est obligatoire. La formation sécurité est dispensée par le responsable en poste le jour de l'intégration.",
                        key: '3',
                      },
                      {
                        value:
                          "4 : Port des EPI obligatoire. Le respect des règles de sécurité et d'hygiène est obligatoire. L'assurance du véhicule est à la charge de l'entreprise utilisatrice.",
                        label:
                          "4 : Port des EPI obligatoire. Le respect des règles de sécurité et d'hygiène est obligatoire. L'assurance du véhicule est à la charge de l'entreprise utilisatrice.",
                        key: '4',
                      },
                    ]}
                  />
                </div>
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep('11', 'b')}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep('13', 'f')}
                  className={`${
                    !order.consigne
                      ? 'bg-gray-200 text-gray-600'
                      : 'bg-mountain-500 text-white'
                  } rounded-md p-4 `}
                  disabled={!order.consigne}
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === '13' && (
            <div className="flex flex-col h-full justify-between">
              <div>
                <p className="font-semibold  text-3xl mx-10">
                  Etre vehiculé(e) ?
                </p>

                <div className="flex items-start my-6 space-x-10 justify-start mx-10">
                  <p className="flex items-center space-x-1">
                    <Checkbox
                      checked={order.isVehicule}
                      onChange={() => selectIsVehicule(true)}
                    />
                    <span>OUI</span>
                  </p>
                  <p className="flex items-center space-x-1">
                    <Checkbox
                      checked={!order.isVehicule}
                      onChange={() => selectIsVehicule(false)}
                    />
                    <span>NON</span>
                  </p>
                </div>
                <p className="font-semibold mx-10 text-3xl my-6">
                 Prerequis :
                </p>

                <div className="flex mx-10 flex-col items-start space-y-6 justify-center">
                  <div className="flex items-start space-x-6">
                    <div>
                      <p className='text-xl'>Diplomes</p>
                      <TreeSelect
                        className="w-96 hover:cursor-pointer text-start text-gray-600"
                        size="large"
                        {...diplomasProps}
                      />
                    </div>
                    <div>
                      <p className='text-xl'>Habilitations</p>
                      <TreeSelect
                        className="w-96 hover:cursor-pointer text-start text-gray-600"
                        size="large"
                        {...habilitationsProps}
                      />
                    </div>
                  </div>

                  <div className="flex items-start space-x-6">
                  <div>
                      <p className='text-xl'>Caces</p>
                      <TreeSelect
                        className="w-96 hover:cursor-pointer text-start text-gray-600"
                        size="large"
                        {...cacesProps}
                      />
                    </div>
                    <div>
                      <p className='text-xl'>Permis</p>
                      <TreeSelect
                        className="w-96 hover:cursor-pointer text-start text-gray-600"
                        size="large"
                        {...licenceProps}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep('12', 'b')}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep('14', 'f')}
                  className="bg-mountain-500 rounded-md p-4 text-white"
                >
                  Suivant
                </button>
              </div>
            </div>
          )}

          {step === '14' && (
            <div className="flex flex-col justify-between h-full">
              <div>
                <p className="font-semibold text-center text-3xl">
                  Quelles sont les tâches/missions ?
                </p>

                <div className="flex items-center my-6 space-x-10 justify-center">
                  <TextArea
                    className="h-20"
                    onChange={(e) => setTask(e.target.value)}
                    value={order.task ? order.task : null}
                    placeholder="Preciser..."
                  />
                </div>
                <p className="text-red-500 text-xl font-semibold text-center">
                  /!\ Ne pas mettre le nom du client !{' '}
                </p>
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep('13', 'b')}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep('15', 'f')}
                  className={`${
                    !order.task
                      ? 'bg-gray-200 text-gray-600'
                      : 'bg-mountain-500 text-white'
                  } rounded-md p-4 `}
                  disabled={!order.task}
                >
                  Suivant
                </button>
              </div>
            </div>
          )}
          {/* 
          {step === '15' && (
            <div className="flex flex-col justify-between h-full">
              <p className="font-semibold text-center text-3xl">facturation</p>

              <div className=" flex items-center space-x-2">
                <input
                  value={facturationDescription}
                  onChange={(e) => setFactureDescription(e.target.value)}
                  className="border outline-none placeholder:text-gray-300 border-gray-300 p-2 rounded-md"
                  placeholder="description"
                />
                <input
                  value={facturationQuantity}
                  onChange={(e) => setFactureQuantity(e.target.value)}
                  className="border outline-none placeholder:text-gray-300 border-gray-300 p-2 rounded-md"
                  placeholder="quantité"
                />
                <input
                  value={facturationRate}
                  onChange={(e) => setFactureRate(e.target.value)}
                  className="border outline-none placeholder:text-gray-300 border-gray-300 p-2 rounded-md"
                  placeholder="taux/HT"
                />
                <button
                  onClick={addFacturation}
                  className="bg-green-500 text-white p-2 rounded-md"
                >
                  Ajouter facturation
                </button>
              </div>

              <div>
                <div className="flex items-center justify-between w-3/4 my-4">
                  <p>Description</p>
                  <p>Quantité</p>
                  <p>Taux/HT</p>
                  <p></p>
                </div>

                {order.facturation &&
                  order.facturation.map((fact, idx) => {
                    return (
                      <div
                        key={idx}
                        className="flex items-center justify-between w-3/4 space-x-3 my-4"
                      >
                        <p>{fact.description}</p>
                        <p>{fact.quantity}</p>
                        <p>{fact.rate}</p>
                        <p onClick={() => deleteFacturationItem(idx)}>
                          <DeleteForeverOutlined />
                        </p>
                      </div>
                    )
                  })}
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep('14', 'b')}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={() => selectStep('16', 'f')}
                  className={`${
                    !order.facturation
                      ? 'bg-gray-200 text-gray-600'
                      : 'bg-mountain-500 text-white'
                  } rounded-md p-4 `}
                  disabled={!order.facturation}
                >
                  Suivant
                </button>
              </div>
            </div>
          )} */}

          {step === '15' && (
            <div className="flex flex-col justify-between h-full">
              <div>
                <p className="font-semibold text-center text-3xl">
                  Qui s'occupe de la commande
                </p>

                <div className="flex items-center my-6 space-x-10 justify-center">
                  <Select
                    className="w-2/4 py-6 my-2 ring-0"
                    showSearch="true"
                    onChange={(e) => selectRecruiter(e)}
                    value={order.recruiter ? `${order.recruiter}` : null}
                    optionFilterProp="children"
                    placeholder="recruteur"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={recruiters}
                  />
                </div>
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep('14', 'b')}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                {order.typeContract === 'Interim' && (
                  <button
                    onClick={() => selectStep('16', 'f')}
                    className={`${
                      order.typeContract === 'Interim' && !order.task
                        ? 'bg-gray-200 text-gray-600'
                        : 'bg-mountain-500 text-white'
                    } rounded-md p-4 `}
                    disabled={order.typeContract === 'Interim' && !order.task}
                  >
                    Suivant
                  </button>
                )}

                {order.typeContract !== 'Interim' && (
                  <button
                    onClick={validateOrder}
                    className={`${
                      order.typeContract === 'Interim' && !order.task
                        ? 'bg-gray-200 text-gray-600'
                        : 'bg-mountain-500 text-white'
                    } rounded-md p-4 `}
                  >
                    Valider la commande
                  </button>
                )}
              </div>
            </div>
          )}

          {step === '16' && (
            <div className="flex flex-col h-full justify-between mt-6">
              {order.typeContract === 'Interim' && (
                <div>
                  <p className="font-semibold text-center text-3xl mb-6">
                    EPI nécessaires
                  </p>

                  <div className="gap-2 grid grid-cols-5">
                    <p
                      onClick={deleteEpi}
                      className={`relative border flex items-center justify-center border-gray-500 ${
                        order.epis?.length === 0 ? 'bg-ultramarine-200' : ''
                      }`}
                    >
                      {order.epis?.length ? (
                        <img className="w-36 h-36" src={epinone} />
                      ) : (
                        <img className="w-36 h-36" src={epinoneselected} />
                      )}
                    </p>
                    {images.map((image) => {
                      return (
                        <p
                          onClick={() => addEpi(image)}
                          className={`relative border flex items-center justify-center border-gray-500 ${
                            order.epis?.includes(image.description)
                              ? 'bg-ultramarine-200'
                              : ''
                          } `}
                        >
                          {order.epis?.includes(image.description) ? (
                            <img className="w-36 h-36" src={image.selected} />
                          ) : (
                            <img className="w-36 h-36" src={image.name} />
                          )}
                        </p>
                      )
                    })}
                  </div>
                </div>
              )}

              {order.typeContract !== 'Interim' && (
                <div>
                  <p className="my-10 font-semibold text-center text-2xl">
                    Valider la commande
                  </p>
                </div>
              )}

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep('15', 'b')}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                {((order.typeContract === 'Interim' && !order.epis.length) ||
                  order.typeContract !== 'Interim') && (
                  <button
                    onClick={validateOrder}
                    className={`bg-mountain-500 text-white rounded-md p-4 `}
                  >
                    Valider la commande
                  </button>
                )}
                {order.epis.length && (
                  <button
                    onClick={() => selectStep('17', 'f')}
                    className="bg-mountain-500 rounded-md p-4 text-white"
                  >
                    Suivant
                  </button>
                )}
              </div>
            </div>
          )}

          {step === '17' && (
            <div className="flex flex-col h-full justify-between">
              <div>
                <p className="font-semibold text-center text-3xl">
                  Qui fournit les EPI ?
                </p>
              </div>

              <div className="flex text-2xl font-bold items-center my-6 space-x-10 justify-center">
                <p
                  onClick={() => selectEpiProvider('EU')}
                  className={`px-2 py-2  rounded-md hover:cursor-pointer ${
                    order.epiProvider === 'EU'
                      ? 'text-white bg-black'
                      : 'text-black border border-black'
                  }`}
                >
                  {' '}
                  EU
                </p>
                <p
                  onClick={() => selectEpiProvider('AGENCE')}
                  className={`px-2 py-2  rounded-md hover:cursor-pointer ${
                    order.epiProvider === 'AGENCE'
                      ? 'text-white bg-black'
                      : 'text-black border border-black'
                  } `}
                >
                  {' '}
                  AGENCE
                </p>
              </div>

              <div className="flex items-center justify-center space-x-2">
                <button
                  onClick={() => selectStep('16', 'b')}
                  className="bg-black rounded-md p-4 text-white"
                >
                  Retour
                </button>
                <button
                  onClick={validateOrder}
                  className={`${
                    !order.epiProvider
                      ? 'bg-gray-200 text-gray-600'
                      : 'bg-mountain-500 text-white'
                  } rounded-md p-4 `}
                >
                  Valider la commande
                </button>
              </div>
            </div>
          )}

          {step === '17' && (
            <div className="h-full mt-10">
              {potentialCandidate.map((candidate) => {
                return (
                  <div>
                    <div className="flex items-center w-full uppercase font-semibold text-xs">
                      <p className="grow">Nom</p>
                      <p className="grow">Prenom</p>
                      <p className="grow">Qualification</p>
                      <p className="grow"></p>
                    </div>
                    <div className="flex items-center space-x-2 mt-6">
                      <p className="grow capitalize">{candidate.firstname}</p>
                      <p className="grow capitalize">{candidate.lastname}</p>
                      <p className="grow">
                        {candidate.qualifications.join(' , ')}
                      </p>
                      <p className="grow text-lg flex items-center space-x-2">
                        <span>
                          <MailOutlined />
                        </span>
                      </p>
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </Modal>

      <div className="flex justify-end ml-20 mb-10 mt-10">
        <div className="flex items-center space-x-2">
          <Input
            className="w-80  rounded-md border border-gray-200 focus:outline-none"
            placeholder="Recherche"
            onChange={(e) => search(e.target.value)}
            style={{ outline: 'none' }}
          />
          <SearchIcon className="text-gray-500 text-3xl" />
          <FilterListOutlinedIcon
            onClick={() => setIsFilterOrder(true)}
            className="text-gray-500 text-3xl"
          />
          <AddCircleOutlineIcon
            onClick={() => setOpenModal(true)}
            className="text-gray-500 text-3xl"
          />
        </div>
      </div>

      <p className="text-2xl font-semibold mb-6">Commandes</p>

      <div className="flex hover:cursor-pointer my-6 items-center w-fit border rounded-lg font-semibold  border-gray-300 bg-[#F7F9FC] ">
        <p
          onClick={() => {
            setFilter('Toutes')
            filterOrders('Toutes')
          }}
          className={`${
            filter === 'Toutes' && 'bg-purple-400'
          } text-black p-2  text-center      w-fit  border-r  border-r-gray-300`}
        >
          Toutes
        </p>
        <p
          onClick={() => {
            setFilter('En cours')
            filterOrders('En cours')
          }}
          className={`${
            filter === 'En cours' && 'bg-purple-400'
          } p-2 text-black w-fit  border-r  text-center  border-r-gray-300`}
        >
          En cours
        </p>
        <p
          onClick={() => {
            setFilter('Aucun candidats')
            filterOrders('Aucun candidat')
          }}
          className={`${
            filter === 'Aucun candidats' && 'bg-purple-400'
          } p-2 text-black w-fit  border-r  text-center  border-r-gray-300`}
        >
          Aucun candidat
        </p>
        <p
          onClick={() => {
            setFilter('Candidats à contacter')
            filterOrders('Candidat à contacter')
          }}
          className={`${
            filter === 'Candidats à contacter' && 'bg-purple-400'
          } p-2  w-fit  border-r text-center text-black border-r-gray-300`}
        >
          Candidats à contacter
        </p>
        <p
          onClick={() => {
            setFilter('Candidats acceptés')
            filterOrders('Candidat accepté')
          }}
          className={`${
            filter === 'Candidats acceptés' && 'bg-purple-400'
          } p-2 border-r w-fit text-center text-black border-r-gray-300`}
        >
          Candidats acceptés
        </p>
        <p
          onClick={() => {
            setFilter('Contrat à faire')
            filterOrders('Contrat à faire')
          }}
          className={`${
            filter === 'Contrat à faire' && 'bg-purple-400'
          } p-2  border-r    w-fit  text-center text-black border-r-gray-300`}
        >
          Contrat à faire
        </p>
        <p
          onClick={() => {
            setFilter('Terminée')
            filterOrders('terminee')
          }}
          className={`${
            filter === 'Terminée' && 'bg-purple-400'
          } p-2  border-r text-center w-fit     text-black border-r-gray-300`}
        >
          Terminée
        </p>
        <p
          onClick={() => {
            setFilter('Annulée')
            filterOrders('annulée')
          }}
          className={`${
            filter === 'Annulée' && 'bg-purple-400'
          } p-2  border-r text-center   w-fit     text-black border-r-gray-300`}
        >
          Annulée
        </p>
      </div>

      <div className="flex grow  items-center py-4 border-b border-b-gray-300 justify-between ">
        <p className="w-[12%] text-center">RECRUTEUR</p>
        <p className="w-[12%] text-center">POSTE</p>
        <p className="w-[6%] text-center">TYPE</p>
        <p className="w-[18%] text-center">CLIENT</p>
        <p className="w-[12%] text-center">DATE DE CONTRAT</p>
        <p className="w-[12%] text-center">LIEU DE MISSION</p>
        <p className="w-[12%] text-center">STATUT</p>
        <p className="w-[12%] text-center">ACTION</p>
      </div>

      <div className="flex flex-col text-sm space-y-2 font-medium">
        {orders.map((order, idx) => {
          return (
            <div
              key={idx}
              className="flex items-center capitalize  text-xs   justify-between bg-white border-b border-b-gray-200   hover:cursor-pointer"
            >
              {!order.loading && (
                <p
                  onClick={() => showCommande(order, idx)}
                  className="w-[12%] text-center "
                >
                  {order?.recruiter?.firstname}
                </p>
              )}
              {order.loading && (
                <p
                  onClick={() => showCommande(order, idx)}
                  className="w-[12%] text-center"
                >
                  <Spin />
                </p>
              )}
              <div
                onClick={() => showCommande(order, idx)}
                className="w-[12%] text-xs text-center flex  items-center justify-center "
              >
                <p>{order?.qualification}</p>
              </div>

              <div
                onClick={() => showCommande(order, idx)}
                className="w-[6%] capitalize flex flex-col items-center justify-center"
              >
                {order.typeContrat === 'Interim' && (
                  <p className="font-semibold  text-blue-500">
                    ({order.typeContrat})
                  </p>
                )}
                {order.typeContrat === 'CDD' && (
                  <p className="font-semibold  text-orange-500">
                    ({order.typeContrat})
                  </p>
                )}
                {order.typeContrat === 'CDI' && (
                  <p className="font-semibold  text-orange-500">
                    ({order.typeContrat})
                  </p>
                )}
                {order.typeContrat === 'Vacation' && (
                  <p className="font-semibold  text-green-500">
                    ({order.typeContrat})
                  </p>
                )}
              </div>

              <div
                onClick={() => showCommande(order, idx)}
                className="w-[18%] capitalize flex flex-col items-center justify-center"
              >
                <p className="text-center w-full">
                  {order.client?.companyName.toLowerCase()}
                </p>
              </div>

              {order.typeContrat !== 'CDI' && (
                <p
                  onClick={() => showCommande(order, idx)}
                  className="w-[12%] text-center  flex flex-col items-center"
                >
                  <span>Du {formatJsDate(order?.startDate)}</span>

                  <span>au {formatJsDate(order?.endDate)}</span>
                </p>
              )}
              {order.typeContrat == 'CDI' && (
                <p
                  onClick={() => showCommande(order, idx)}
                  className="w-[12%] text-center "
                >
                  {formatJsDate(order?.startDate)}
                </p>
              )}
              <p className="w-[12%] text-center ">{order?.town}</p>
              <p className="w-[12%] text-center ">{order?.status}</p>
              <p className="w-[12%] flex items-center justify-center ">
                <Popover
                  placement="bottom"
                  content={
                    <div className="flex flex-col capitalize text-sm space-y-3">
                      <p
                        onClick={(e) => deleteOrder(e, order._id)}
                        className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                      >
                        <span>Supprimer</span>
                      </p>
                    </div>
                  }
                  trigger="click"
                >
                  <Button>...</Button>
                </Popover>
              </p>
            </div>
          )
        })}
      </div>
      {isOriginal && (
        <div className="text-right mr-2">
          <Pagination
            className="mt-6 w-full"
            current={currentPage}
            onChange={onChangePage}
            total={documentCount}
            pageSize={20}
          />
        </div>
      )}
    </div>
  )
}

export default Commandes
