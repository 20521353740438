import { Checkbox, Select } from 'antd'
import qualifications from '../../../qualifications/qualifications'
import { useEffect, useState } from 'react'
import TextArea from 'antd/es/input/TextArea'

const CreateContractPoste = ({
  contract,
  setContract,
  qualifications,
  primes,
  globalPrimes,
}) => {
  const [isEditTauxHoraire, setIsEditTauxHoraire] = useState(false)
  const [primeGlobal, setPrimeGlobal] = useState([])
  const [selectedQualification, setSelectedQualification] = useState(null)
  const [selectedPrimes, setSelectedPrimes] = useState(primes)
  const [usedPrimes, setUsedPrimes] = useState(primes)
  useEffect(() => {
    if (!contract.TabPrimes) {
      setContract({ ...contract, isEditTauxHoraire: false })
    }
  }, [])

  return (
    <div className="flex flex-col h-full justify-between">
      <div>
        <p className="font-semibold text-center text-3xl">Poste</p>

        <div className="flex flex-col h-40 items-start my-6 overflow-y-scroll space-y-3">
          {qualifications?.map((qualification, idx) => {
            return (
              <div
                className={`${
                  contract?.clientQualification?.IdQualification ===
                    qualification?.IdQualification &&
                  contract?.clientQualification?.Niveau ===
                    qualification?.Niveau
                    ? 'bg-purple-200 text-purple-800'
                    : ''
                } p-2 w-full `}
                onClick={() => {
                  setContract({
                    ...contract,
                    clientQualification: qualification,
                  })
                  setSelectedQualification(qualification)
                }}
                key={idx}
              >
                <p className="font-semibold">{qualification.IdQualification}</p>
                <p>{qualification.TxtQualification}</p>
                <p className="">Coefficient : {qualification.CoeffHeures}</p>
                {qualification.Niveau !== '' && (
                  <p>Niveau {qualification.Niveau}</p>
                )}
                {qualification.Niveau === '' && <p>Niveau N/C</p>}
                <p>{qualification.PCS}</p>
                <p>{qualification.TauxPaye}&euro;/h</p>
              </div>
            )
          })}
        </div>
        <p className="text-center">
          <button
            onClick={() => {
              setIsEditTauxHoraire(!isEditTauxHoraire)
              setContract({
                ...contract,
                isEditTauxHoraire: !isEditTauxHoraire,
              })
            }}
            className="bg-purple-500 text-white rounded-md p-2"
          >
            Editer le taux horaire
          </button>
        </p>

        {isEditTauxHoraire && (
          <div className="mt-6 flex items-center space-x-2 justify-center">
            <input
              onChange={(e) => {
                setContract({ ...contract, coefficient: e.target.value })
              }}
              className="border border-gray-300 rounded-md p-2 outline-none"
              placeholder="Coefficient"
            />
            <input
              onChange={(e) => {
                setContract({ ...contract, tauxHoraire: e.target.value })
              }}
              className="border border-gray-300 rounded-md p-2 outline-none"
              placeholder="Taux horaire"
            />
          </div>
        )}

        <div>
          <p className="text-lg mb-4">Caracteristiques du poste</p>
          <TextArea
            value={contract.TxtConsigneQualification}
            onChange={(e) => {
              setContract({
                ...contract,
                TxtConsigneQualification: e.target.value,
              })
            }}
            className="border border-gray-300 rounded-md"
          />
        </div>

        <div className="mt-6 flex flex-col space-y-2">
          {/*  <div className='h-80 overflow-scroll'>
           {usedPrimes?.map((pr)=> {
            return <div key={pr.IdPrime} onClick={(()=> alert(JSON.stringify(pr)))} className='flex items-center space-x-2 text-lg'>
              <Checkbox onChange={(e)=> selectPrimes(e,pr)} checked={selectedPrimes.some((p)=>p.IdPrime===pr.IdPrime)} />
              <p className='flex items-center space-x-2'>
                <span>{pr.TxtPrime}</span>
                 {pr.TauxPayeNonImposable!==0&&<span>{pr.TauxPayeNonImposable}&euro;</span>}
                  {pr.TauxPayeImposable!==0&&<span>{pr.TauxPayeImposable}&euro;</span>}
                  </p>

              </div>
          })} 
          {primeGlobal?.map((pr)=> {
            return <div key={pr.IdPrime} onClick={(()=> alert(JSON.stringify(pr)))} className='flex items-center space-x-2 text-lg'>
              <Checkbox onChange={(e)=> selectPrimes(e,pr)} checked={selectedPrimes.some((p)=>p.IdPrime===pr.IdPrime)} />
              <p className='flex items-center space-x-2'>
                <span>{pr.Designation}</span>
                  </p>

              </div>
          })} 
           </div> */}
        </div>
      </div>
    </div>
  )
}

export default CreateContractPoste
