import {
  AppstoreOutlined,
  UserOutlined,
  SettingOutlined,
  ShopOutlined,
} from '@ant-design/icons'
import logo from '../../assets/img/logo.png'
import logoutImg from '../../assets/img/logout.png'
import taches from '../../assets/img/taches.png'
import gestion from '../../assets/img/gestion.png'
import gestionPaie from '../../assets/img/gestion-paie.png'
import monEspace from '../../assets/img/monespace.png'
import candidats from '../../assets/img/candidats.png'
import { AuthContext } from '../../context/authContext'
import home from '../../assets/img/home-line.png'
import briefcase from '../../assets/img/briefcase-01.png'
import client2 from '../../assets/img/client2.png'
import candidat2 from '../../assets/img/candidat2.png'
import commande from '../../assets/img/commande.png'
import annonce from '../../assets/img/annonce.png'
import facture from '../../assets/img/facture2.png'
import switchImg from '../../assets/img/switch.png'
import users from '../../assets/img/user-01.png'
import file from '../../assets/img/file-05.png'
import { Button, Menu, Popover, Spin, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import dashboard from '../../assets/img/icon-dashboard.png'
import star from '../../assets/img/star.png'
import AxiosInstance from '../../axios/axiosInstance'
import { DocumentScannerOutlined, SettingsOutlined } from '@mui/icons-material'
import { LoadingOutlined } from '@ant-design/icons'

const Menu2 = ({ toggle, toggleDashboard, selected, setSelected }) => {
  const { setIsLoggedIn } = useContext(AuthContext)
  const navigate = useNavigate()
  const [isReloaded, setIsReloaded] = useState(['a'])
  const [agencyName, setAgencyName] = useState('')

  const agencies = [
    {
      id: '650adee7951dd697c7535e3e',
      name: 'Asnieres',
    },
    {
      id: '6533fe25099978decf532a59',
      name: 'Brive',
    },
    {
      id: '654a3d82874a059df3f98653',
      name: 'Toulouse',
    },
  ]

  const switchAgency = (agencyName, agencyId) => {
    AxiosInstance.post('agency/switch', { agency: agencyId })
      .then((res) => {
        setIsReloaded([...isReloaded])
        setAgencyName(agencyName)
        localStorage.setItem('agency', agencyId)
        toggle('dashboard')
        toggleDashboard()
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          message.error("Vous n'etes pas autorisé à changer d'agence !")
        }
      })
  }

  useEffect(() => {
    getAgency()
  }, isReloaded)

  const getAgency = () => {
    const agencyId = localStorage.getItem('agency')
    const agency = agencies.find((ag) => ag.id === agencyId)
    if (agency) setAgencyName(agency.name)
  }

  const click = (item) => {
    if (item !== 'gestion' && item !== 'sub4') toggle(item)
    if (selected === 'gestion' && item === 'gestion') {
      setSelected('')
    } else if (selected === 'sub4' && item === 'sub4') {
      setSelected('')
    } else if (selected === 'gestion-paie' && item === 'gestion-paie') {
      setSelected('')
    } else if (selected === 'parametres' && item === 'parametres') {
      setSelected('')
    } else if (selected === 'interimaires' && item === 'interimaires') {
      setSelected('')
    } else setSelected(item)
  }

  const logout = () => {
    setIsLoggedIn(false)
    localStorage.removeItem('accessToken')
    localStorage.removeItem('isLogged')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('userEmail')
    localStorage.removeItem('userRole')
    navigate('/login')
  }

  function getItem(label, key, icon, onClick, children) {
    return {
      key,
      icon,
      children,
      onClick,
      label,
    }
  }

  const clientItems = [
    'sub4',
    'searchClient',
    'devis',
    'clientContract',
    'facture',
    'registre',
  ]

  const interimairesItems = [
    'interimaires',
    'planning',
    'candidatContract',
    'pay',
    'attestation',
    'certificatTravail',
    'fiscal',
  ]

  const settingsItems = ['parametres', 'utilisateurs', 'annuaire']

  const payGestionItems = [
    'facture-manuelle',
    'rh',
    'saisie',
    'renseignement',
    'DemandeContrat',
    'acompte',
    'maladie',
    'gestion-paie',
  ]
  const agencyGestionItems = [
    'gestion',
    'annonces',
    'atradius',
    'order',
    'controleReference',
    'prolongations',
    'WaitingDocument',
    'visite',
  ]
  const items = [
    getItem(
      'Dashboard',
      'dashboard',
      <img className="w-6" src={dashboard} />,
      click,
    ),
    getItem('Gestion', 'gestion', <SettingOutlined />, null, [
      getItem(
        'Tâches du jour',
        'taches',
        <img className="w-5 rounded-full" src={star} />,
        click,
      ),
      getItem('Absences', 'maladie', null, click),
      getItem('Annonces', 'annonces', null, click),
      getItem('Atradius', 'atradius', null, click),
      getItem('Acomptes', 'acompte', null, click),
      getItem('Commande', 'order', null, click),
      getItem('Contrat freelance', 'DemandeContrat', null, click),
      getItem('Controle de reference', 'controleReference', null, click),
      getItem('Factures à faire', 'facture-manuelle', null, click),
      getItem('Fiche renseignement', 'renseignement', null, click),
      getItem('Prolongations', 'prolongations', null, click),
      getItem("Relevés d'heures", 'rh', null, click),
      getItem('Saisie arret', 'saisie', null, click),
      getItem('Validation de documents', 'WaitingDocument', null, click),
      getItem('Visite médicale', 'visite', null, click),
    ]),
    getItem('Candidats', 'candidats', <UserOutlined />, null, [
      getItem('Rechercher un candidat', 'searchCandidat', null, click),
      getItem('Candidats disponibles', 'availableCandidat', null, click),
    ]),
    getItem('Interimaires', 'interimaires', <UserOutlined />, null, []),
    getItem('Clients', 'sub4', <ShopOutlined />, null, [
      getItem('Rechercher un client', 'searchClient', null, click),
      getItem('Devis', 'devis', null, click),
      getItem('Contrat', 'clientContract', null, click),
      getItem('Factures', 'facture', null, click),
      getItem('Registre du personnel', 'registre', null, click),
    ]),
    getItem('Bibliothèque', 'documents', <DocumentScannerOutlined />, click),
    getItem(
      'Mon espace personnel',
      'perso',
      <DocumentScannerOutlined />,
      click,
    ),
    getItem('Paramètres', 'parametres', <SettingOutlined />, null, [
      getItem('Utilisateurs', 'utilisateurs', null, click),
      getItem('Annuaire', 'annuaire', null, click),
      getItem('Switcher agence', 'switch', null, null, [
        getItem('Agence Asnieres', 'Asnieres', null, () =>
          switchAgency('Asnieres', '650adee7951dd697c7535e3e'),
        ),
        getItem('Agence Brive', 'Brive', null, () =>
          switchAgency('Brive', '6533fe25099978decf532a59'),
        ),
        getItem('Agence Toulouse', 'Toulouse', null, () =>
          switchAgency('Toulouse', '654a3d82874a059df3f98653'),
        ),
      ]),

      getItem('Deconnexion', 'deconnexion', null, logout),
    ]),

    ,
  ]

  return (
    <>
      {/* <Spin spinning={true} fullscreen indicator={<LoadingOutlined />} size='large' /> */}
      <div className="text-base font-Inter bg-gray-50 bg-opacity-100 border-r border-r-gray-300  flex flex-col  overflow-y-auto text-black fixed top-0 left-0 h-full">
        <img className="w-24 mx-auto mt-4 mb-8" src={logo} />
        <p className="text-center text-black -mt-6 mb-6 text-md italic">
          {agencyName}
        </p>

        {/*  <Menu
          className="grow flex flex-col space-y-6 pb-10 font-Inter text-lg bg-gray-50 text-black"
          style={{
            width: 256,
            backgroundColor: '#4875ce',
          }}
          mode="inline"
          items={items}
          defaultOpenKeys={['sub3']}
        /> */}

        <div className="w-[256px] flex flex-col items-center font-medium justify-center space-y-6">
          <p
            onClick={() => {
              click('dashboard')
            }}
            className={`${
              selected === 'dashboard' ? 'bg-[#EBE9FE]' : ''
            } p-2 rounded-md hover:cursor-pointer w-4/5 flex items-center space-x-2`}
          >
            <img src={home} className="w-5" />
            <span>Tableau de bord</span>
          </p>

          <p
            onClick={() => {
              click('taches')
            }}
            className={`${
              selected === 'taches' ? 'bg-[#EBE9FE]' : ''
            } p-2 rounded-md hover:cursor-pointer w-4/5 flex items-center space-x-2`}
          >
            <img src={taches} className="w-5" />
            <span>Tâches du jour</span>
          </p>

          <p
            onClick={() => {
              click('gestion')
            }}
            className={`${
              agencyGestionItems.includes(selected) ? 'bg-[#EBE9FE]' : ''
            } p-2 rounded-md hover:cursor-pointer w-4/5 flex items-center space-x-2`}
          >
            <img src={gestion} className="w-5" />
            <span>Gestion agence</span>
          </p>
          {agencyGestionItems.includes(selected) && (
            <div className="flex flex-col space-y-2 px-4">
              <p
                onClick={() => click('annonces')}
                className={`${
                  selected === 'annonces' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer px-8 py-1 rounded-md flex items-center space-x-2`}
              >
                <span>Annonces</span>
              </p>
              <p
                onClick={() => click('atradius')}
                className={`${
                  selected === 'atradius' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer px-8 py-1 rounded-md flex items-center space-x-2`}
              >
                <span>Atradius</span>
              </p>
              <p
                onClick={() => click('order')}
                className={`${
                  selected === 'order' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer px-8 py-1 rounded-md flex items-center space-x-2`}
              >
                <span>Commandes</span>
              </p>
              <p
                onClick={() => click('controleReference')}
                className={`${
                  selected === 'controleReference' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer px-8 py-1 rounded-md flex items-center space-x-2`}
              >
                <span>Controle de reference</span>
              </p>
              <p
                onClick={() => click('prolongations')}
                className={`${
                  selected === 'prolongations' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer px-8 py-1 rounded-md flex items-center space-x-2`}
              >
                <span>Prolongations</span>
              </p>
              <p
                onClick={() => click('WaitingDocument')}
                className={`${
                  selected === 'WaitingDocument' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer px-8 py-1 rounded-md`}
              >
                {' '}
                Documents
              </p>
              <p
                onClick={() => click('visite')}
                className={`${
                  selected === 'visite' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer px-8 py-1 rounded-md`}
              >
                {' '}
                Visite médicale
              </p>
            </div>
          )}

          <p
            onClick={() => {
              click('gestion-paie')
            }}
            className={`${
              payGestionItems.includes(selected) ? 'bg-[#EBE9FE]' : ''
            } p-2 rounded-md hover:cursor-pointer w-4/5 flex items-center space-x-2`}
          >
            <img src={gestionPaie} className="w-5" />
            <span>Gestion paie</span>
          </p>

          {payGestionItems.includes(selected) && (
            <div className="flex flex-col space-y-2 px-4">
              <p
                onClick={() => click('maladie')}
                className={`${
                  selected === 'maladie' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer px-8 py-1 rounded-md flex items-center space-x-2`}
              >
                <span>Absences</span>
              </p>
              <p
                onClick={() => click('acompte')}
                className={`${
                  selected === 'acompte' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer px-8 py-1 rounded-md flex items-center space-x-2`}
              >
                <span>Acomptes</span>
              </p>
              <p
                onClick={() => click('DemandeContrat')}
                className={`${
                  selected === 'DemandeContrat' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer px-8 py-1 rounded-md flex items-center space-x-2`}
              >
                <span>Contrat freelance</span>
              </p>
              <p
                onClick={() => click('facture-manuelle')}
                className={`${
                  selected === 'facture-manuelle' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer px-8 py-1 rounded-md flex items-center space-x-2`}
              >
                <span>Factures à faire</span>
              </p>
              <p
                onClick={() => click('renseignement')}
                className={`${
                  selected === 'renseignement' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer px-8 py-1 rounded-md flex items-center space-x-2`}
              >
                <span>Fiche renseignement</span>
              </p>
              <p
                onClick={() => click('rh')}
                className={`${
                  selected === 'rh' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer px-8 py-1 rounded-md flex items-center space-x-2`}
              >
                <span>Relevés d'heures</span>
              </p>
              <p
                onClick={() => click('saisie')}
                className={`${
                  selected === 'saisie' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer px-8 py-1 rounded-md flex items-center space-x-2`}
              >
                <span>Saisie arret</span>
              </p>
            </div>
          )}

          <p
            onClick={() => {
              click('searchCandidat')
            }}
            className={`${
              selected === 'searchCandidat' ? 'bg-[#EBE9FE]' : ''
            } p-2 rounded-md hover:cursor-pointer w-4/5 flex items-center space-x-2`}
          >
            <img src={candidats} className="w-6" />
            <span>Candidats</span>
          </p>

          <p
            onClick={() => {
              click('birthday')
            }}
            className={`${
              selected === 'searchCandidat' ? 'bg-[#EBE9FE]' : ''
            } p-2 rounded-md hover:cursor-pointer w-4/5 flex items-center space-x-2`}
          >
            <img src={candidats} className="w-6" />
            <span>Anniversaire</span>
          </p>
          <p
            onClick={() => {
              click('interimaires')
            }}
            className={`${
              interimairesItems.includes(selected) ? 'bg-[#EBE9FE]' : ''
            } p-2 rounded-md hover:cursor-pointer w-4/5 flex items-center space-x-2`}
          >
            <img src={candidat2} className="w-6" />
            <span>Interimaires</span>
          </p>
          {interimairesItems.includes(selected) && (
            <div className="flex flex-col space-y-2 px-4 ">
              <p
                onClick={() => click('planning')}
                className={`${
                  selected === 'planning' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer px-8 py-1 rounded-md`}
              >
                Planning
              </p>

              <p
                onClick={() => click('candidatContract')}
                className={`${
                  selected === 'candidatContract' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer px-8 py-1 rounded-md`}
              >
                Contrat
              </p>

              <p
                onClick={() => click('pay')}
                className={`${
                  selected === 'pay' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer px-8 py-1 rounded-md`}
              >
                Fiche de paie
              </p>

              <p
                onClick={() => click('attestation')}
                className={`${
                  selected === 'attestation' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer px-8 py-1 rounded-md`}
              >
                Attestation FT
              </p>

              <p
                onClick={() => click('certificatTravail')}
                className={`${
                  selected === 'certificatTravail' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer px-8 py-1 rounded-md`}
              >
                Certificat de travail
              </p>

              <p
                onClick={() => click('fiscal')}
                className={`${
                  selected === 'fiscal' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer px-8 py-1 rounded-md`}
              >
                Attestation fiscale
              </p>
            </div>
          )}

          <p
            onClick={() => {
              click('sub4')
            }}
            className={`${
              clientItems.includes(selected) ? 'bg-[#EBE9FE]' : ''
            } p-2 rounded-md hover:cursor-pointer w-4/5 flex items-center space-x-2`}
          >
            <img src={client2} className="w-6" />
            <span>Clients</span>
          </p>
          {clientItems.includes(selected) && (
            <div className="flex flex-col space-y-2 px-4 ">
              <p
                onClick={() => click('searchClient')}
                className={`${
                  selected === 'searchClient' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer px-8 py-1 rounded-md`}
              >
                Rechercher un client
              </p>

              <p
                onClick={() => click('devis')}
                className={`${
                  selected === 'devis' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer px-8 py-1 rounded-md`}
              >
                Devis
              </p>

              <p
                onClick={() => click('clientContract')}
                className={`${
                  selected === 'clientContract' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer px-8 py-1 rounded-md`}
              >
                Contrat
              </p>

              <p
                onClick={() => click('facture')}
                className={`${
                  selected === 'facture' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer px-8 py-1 rounded-md`}
              >
                Factures
              </p>

              <p
                onClick={() => click('registre')}
                className={`${
                  selected === 'registre' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer px-8 py-1 rounded-md`}
              >
                Registre du personnel
              </p>
            </div>
          )}

          <p
            onClick={() => {
              click('documents')
            }}
            className={`${
              selected === 'documents' ? 'bg-[#EBE9FE]' : ''
            } p-2 rounded-md hover:cursor-pointer w-4/5 flex items-center space-x-2`}
          >
            <img src={file} className="w-5" />
            <span>Bibliothèque</span>
          </p>

         {/*  <p
            onClick={() => {
              click('freelance')
            }}
            className={`${
              selected === 'perso' ? 'bg-[#EBE9FE]' : ''
            } p-2 rounded-md hover:cursor-pointer w-4/5 flex items-center space-x-2`}
          >
            <img src={monEspace} className="w-5" />
            <span>Freelance</span>
          </p> */}

          <p
            onClick={() => {
              click('perso')
            }}
            className={`${
              selected === 'perso' ? 'bg-[#EBE9FE]' : ''
            } p-2 rounded-md hover:cursor-pointer w-4/5 flex items-center space-x-2`}
          >
            <img src={monEspace} className="w-5" />
            <span>Mon espace</span>
          </p>

          <p
            onClick={() => {
              click('parametres')
            }}
            className={`${
              selected === 'parametres' ? 'bg-[#EBE9FE]' : ''
            } p-2 rounded-md hover:cursor-pointer w-4/5 flex items-center space-x-2`}
          >
            <SettingsOutlined className="w-6 text-gray-500" />
            <span>Paramètres</span>
          </p>
          {settingsItems.includes(selected) && (
            <div className="flex flex-col space-y-2 w-full px-16">
              <p
                onClick={() => click('utilisateurs')}
                className={`${
                  selected === 'utilisateurs' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer  py-1 rounded-md`}
              >
                Utilisateurs
              </p>

              <p
                onClick={() => click('annuaire')}
                className={`${
                  selected === 'annuaire' ? 'bg-[#EBE9FE]' : ''
                } hover:cursor-pointer  py-1 rounded-md`}
              >
                Annuaire
              </p>
            </div>
          )}

          <p
            className={` p-2 rounded-md hover:cursor-pointer w-4/5 flex space-x-2`}
          >
            <img src={switchImg} className="w-6" />
            <Popover
              className="flex items-start"
              placement="bottom"
              content={
                <div className="flex flex-col capitalize text-base space-y-3">
                  <p
                    onClick={() =>
                      switchAgency('Asnieres', '650adee7951dd697c7535e3e')
                    }
                    className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                  >
                    <span>Asnieres sur seine</span>
                  </p>
                  <p
                    onClick={() =>
                      switchAgency('Brive', '6533fe25099978decf532a59')
                    }
                    className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                  >
                    <span>Brive</span>
                  </p>
                  <p
                    onClick={() =>
                      switchAgency('Toulouse', '654a3d82874a059df3f98653')
                    }
                    className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                  >
                    <span>Toulouse</span>
                  </p>
                </div>
              }
              trigger="click"
            >
              <p>Switcher agence</p>
            </Popover>
          </p>
          <p
            onClick={() => {
              logout()
            }}
            className={` p-2 rounded-md hover:cursor-pointer w-4/5 flex items-center space-x-2`}
          >
            <img src={logoutImg} className="w-5" />
            <span>Deconnexion</span>
          </p>
        </div>
      </div>
    </>
  )
}
export default Menu2
