import { Select } from 'antd'
import { useEffect, useState } from 'react'

const CreateContractContact = ({
  client,
  contract,
  setContract,
  interlocuteur,
}) => {
  const clientContactsArray = client.contacts.map((contact, idx) => {
    return {
      value: `${contact.firstname?.trim() || ''} ${
        contact.lastname?.trim() || ''
      } ${contact.email?.trim() || ''} ${contact.mobile?.trim() || ''}`,
      label: `${contact.firstname?.trim() || ''} ${
        contact.lastname?.trim() || ''
      } ${contact.email?.trim() || ''} ${contact.mobile?.trim() || ''}`,
      key: idx,
    }
  })

  useEffect(() => {
    for (let i = 0; i < clientContactsArray.length; i++) {
      if (clientContactsArray[i].value.includes(interlocuteur)) {
        setContract({ ...contract, contact: clientContactsArray[i].value })
      }
    }
  }, [interlocuteur])

  return (
    <div className="flex flex-col h-full justify-between">
      <div>
        <p className="font-semibold text-center text-3xl">Contact</p>

        <Select
          className="w-full py-6 my-2 ring-0"
          onChange={(e) => setContract({ ...contract, contact: e })}
          value={contract.contact ? `${contract.contact}` : null}
          optionFilterProp="children"
          placeholder="Contact"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={clientContactsArray}
        />
      </div>
    </div>
  )
}

export default CreateContractContact
