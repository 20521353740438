import { useState, useEffect } from 'react'
import axios from '../../axios/axiosInstance'
import { Button, Input, Popover, Modal, message, Pagination } from 'antd'
import xlsIcon from '../../assets/img/xls.png'
import SearchIcon from '@mui/icons-material/Search'
import { Puff } from 'react-loader-spinner'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import { RefreshOutlined } from '@mui/icons-material'

const Atradius = () => {
  const [covers, setCovers] = useState([])
  const [coversToUpdate, setCoversToUpdate] = useState([])
  const [originalCovers, setOriginalCovers] = useState([])
  const [filter, setFilter] = useState('Tous')
  const [query, setQuery] = useState('')
  const [selectedCover, setSelectedCover] = useState(null)
  const [isReNew, setIsReNew] = useState(false)
  const [amount, setAmount] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [documentCount, setDocumentCount] = useState(0)
  const [isOriginal, setIsOriginal] = useState(true)
  const [loadingFilter, setLoadingFilter] = useState(false)
  const [isATraiter, setIsATraiter] = useState(false)
  const [isTraiterCoverModal, setIsTraiterCoverModal] = useState(false)
  const [selectedCoverATraiter, setSelectedCoverATraiter] = useState(null)
  const [actionMeneeString, setActionMeneeString] = useState('')
  const [isVoirTraitement, setIsVoirTraitement] = useState(false)

  useEffect(() => {
    axios
      .get(`cover/count`)
      .then((res) => setDocumentCount(res.data))
      .catch((e) => {})
    loadCovers()
  }, [])

  const timestampToDate = (timestamp) => {
    const date = new Date(timestamp * 1000)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()

    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')

    return `${day}/${month}/${year} ${hours}:${minutes}`
  }

  const traiterCouverture = () => {
    axios
      .put(`cover/update-action/${selectedCoverATraiter.coverId}`, {
        actionMenee: selectedCoverATraiter.actionMenee,
        actionMeneeString,
      })
      .then((res) => {
        setActionMeneeString('')
        setSelectedCoverATraiter(null)
        setIsTraiterCoverModal(false)
        message.success('Couverture traitée !')
      })
      .catch((e) => {
        console.log(e)
        message.error("Une erreur s'est produite !")
      })
  }

  const handleExportClick = () => {
    exportToExcel(covers)
  }

  const exportToExcel = (data) => {
    const newData = covers.map((cover) => {
      return {
        'Date de la demande': formatJsDate(cover._doc.decisionDate),
        Client: cover._doc.client.companyName,
        Siret: cover._doc.client.siret,
        "Type d'assurance": cover._doc.coverType,
        'Montant demandé':
          cover._doc.creditLimitApplicationAmountInPolicyCurrency,
        'Montant accordé': cover._doc.totalDecision.decisionAmtInPolicyCurrency,
        'Facture en cours': cover.montantNonFacture.toFixed(2),
      }
    })

    const workbook = XLSX.utils.book_new()
    const sheet = XLSX.utils.json_to_sheet(newData)

    XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet 1')

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    })

    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    saveAs(blob, 'atradius.xlsx')
  }

  const getFilteredCovers = (status) => {
    if (status === 'Tous') {
      setCovers(originalCovers)
      setCurrentPage(1)
      setIsOriginal(true)
      return
    }
    setIsOriginal(false)
    setLoadingFilter(true)
    axios
      .get(`cover/filter/${status}`)
      .then((res) => setCovers(res.data))
      .catch((e) => {
        console.log(e)
      })
      .finally(() => setLoadingFilter(false))
  }

  const onChangePage = (page, pageSize) => {
    axios
      .get(`cover/paginate/${page - 1}`)
      .then((res) => {
        setCovers(res.data)
        setCurrentPage(page)
        console.log(res.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const loadCovers = () => {
    axios
      .get('cover')
      .then((res) => {
        console.log(res.data)
        setCovers(res.data)
        setOriginalCovers(res.data)
      })
      .catch((e) => console.log(e))

    axios
      .get('cover/to-update')
      .then((res) => {
        setCoversToUpdate(res.data)
      })
      .catch((e) => console.log(e))
  }

  const renew = () => {
    axios
      .put('cover/renew', {
        buyerId: selectedCover.buyerId,
        amount,
        coverType: selectedCover.coverType,
      })
      .then((res) => {
        const resMessage = res.data.data[0].response
        if (resMessage.includes('refused')) {
          message.error(resMessage)
        } else message.success(resMessage)
      })
      .catch((e) => {
        console.log(e)
        message.error("Une erreur s'est produite ! ")
      })
      .finally(() => {
        setIsReNew(false)
        setSelectedCover(null)
        setAmount('')
      })
  }

  const findByText = () => {
    axios
      .post(`cover/find-by-text/`, { query })
      .then((res) => {
        setCovers(res.data)
        setIsOriginal(false)
      })
      .catch((e) => {
        console.log(e)
        message.error("Une erreur s'est produite !")
      })
  }

  const cancel = (cover) => {
    console.log('cllalalalalal')
    axios
      .put('cover/cancel', {
        coverType: cover.coverType,
        buyerId: cover.buyerId,
      })
      .then((res) => {
        message.success('La couverture a été annulée !')
      })
      .catch((e) => message.error("Une erreur s'est produite !"))
  }

  const refresh = (cover) => {
    axios
      .get(`cover/refresh/${cover.buyerId}/${cover.coverId}`)
      .then((res) => {
        const newCover = res.data
        const coverIdx = covers.findIndex(
          (cvr) => cvr.buyerId === newCover.buyerId,
        )
        const newCovers = [...covers]
        newCovers[coverIdx] = newCover
        setCovers(newCovers)
        message.success('Couverture mise à jour !')
      })
      .catch((e) => {
        console.log(e)
        message.error("Une erreur s'est produite !")
      })
  }

  const formatJsDate = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${day}/${month}/${year}`
  }

  return (
    <div>
      {isTraiterCoverModal && (
        <Modal
          open={isTraiterCoverModal}
          onCancel={() => setIsTraiterCoverModal(false)}
          footer={null}
          width={600}
        >
          <div className="mt-10 h-96 flex flex-col justify-center space-y-2">
            <p className="font-semibold text-2xl">Action menée</p>
            <textarea
              onChange={(e) => setActionMeneeString(e.target.value)}
              className="w-full h-48 rounded-md border border-gray-300"
              placeholder="Action menée"
            ></textarea>
            <button
              onClick={() => traiterCouverture()}
              className="bg-purple-500 p-2 text-white rounded-md w-full"
            >
              Traiter la couverture
            </button>
          </div>
        </Modal>
      )}

      {isVoirTraitement && (
        <Modal
          open={isVoirTraitement}
          onCancel={() => {
            setIsVoirTraitement(false)
            setSelectedCoverATraiter(null)
          }}
          footer={null}
        >
          <div className="mt-10 h-96 overflow-scroll flex flex-col space-y-1">
            <p className="text-2xl font-semibold">
              Traitement de la couverture
            </p>
            {selectedCoverATraiter._doc.actionMenee.map((traitement, idx) => {
              return (
                <div key={idx}>
                  <p className="capitalize text-lg">
                    {traitement.username} - {timestampToDate(traitement.date)}
                  </p>
                  <p className="text-lg">{traitement.actionMenee}</p>
                </div>
              )
            })}
          </div>
        </Modal>
      )}

      <div className="mt-10 mb-6 flex py-2 border border-gray-300 px-2 bg-[#F7F9FC] space-x-2 rounded-md items-center justify-between">
        <div
          onClick={() => setIsATraiter(false)}
          className={`${
            !isATraiter ? 'bg-white border border-gray-200' : ''
          } py-4  w-1/2 hover:cursor-pointer rounded-xl`}
        >
          <p className="text-center font-bold">Suivi</p>
        </div>

        <div
          onClick={() => setIsATraiter(true)}
          className={`${
            isATraiter ? 'bg-white border border-gray-200' : ''
          } py-4 w-1/2 hover:cursor-pointer  rounded-xl`}
        >
          <p className="text-center font-bold">A traiter</p>
        </div>
      </div>
      {loadingFilter && (
        <div className="w-full h-full absolute top-0 z-30 right-0 bg-gray-400 bg-opacity-40 flex items-center justify-center">
          <Puff
            height="80"
            width="80"
            radius={1}
            color="#4fa94d"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
      {isReNew && (
        <Modal
          open={isReNew}
          footer={null}
          onCancel={() => {
            setIsReNew(false)
            setSelectedCover(null)
            setAmount('')
          }}
        >
          <div className="mt-10 flex flex-col space-y-2 items-center justify-center">
            <Input
              onChange={(e) => setAmount(e.target.value)}
              value={amount}
              className="w-96 rounded-md p-2 border border-gray-300"
              placeholder="Nouveau Montant"
            />
            <button
              onClick={renew}
              className="w-96 rounded-md p-2 bg-purple-500 text-white"
            >
              RENOUVELLER
            </button>
          </div>
        </Modal>
      )}

      {!isATraiter && (
        <div>
          <p className="font-semibold text-lg">Atradius</p>
        </div>
      )}

      {!isATraiter && (
        <div className="flex items-center justify-between w-full mt-6">
          <div className="flex hover:cursor-pointer items-center  border rounded-lg font-semibold  border-gray-300 bg-[#F7F9FC] ">
            <p
              onClick={() => {
                setFilter('Tous')
                getFilteredCovers('Tous')
              }}
              className={`${
                filter === 'Tous' && 'bg-purple-400'
              } text-black py-2 w-20 text-center  border-r  border-r-gray-300`}
            >
              Tous
            </p>
            <p
              onClick={() => {
                setFilter('Ok')
                getFilteredCovers('Ok')
              }}
              className={`${
                filter === 'Ok' && 'bg-purple-400'
              } py-2 text-black border-r w-20 text-center  border-r-gray-300`}
            >
              Ok
            </p>
            <p
              onClick={() => {
                setFilter('A revoir')
                getFilteredCovers('A revoir')
              }}
              className={`${
                filter === 'A revoir' && 'bg-purple-400'
              } py-2 w-20 border-r text-center text-black border-r-gray-300`}
            >
              A revoir
            </p>
            <p
              onClick={() => {
                setFilter('refusee')
                getFilteredCovers('Refusée')
              }}
              className={`${
                filter === 'refusee' && 'bg-purple-400'
              } py-2 w-20 border-r text-center text-black border-r-gray-300`}
            >
              Refusée
            </p>
          </div>
          <div className="text-left flex space-x-1 mb-6 h-10 items-center w-full justify-end pr-10">
            <div className="flex items-center justify-center space-x-2">
              <Input
                className={`w-80 rounded-md border border-gray-200 focus:outline-none`}
                placeholder="Recherche"
                onChange={(e) => {
                  setQuery(e.target.value)
                  if (e.target.value.length === 0) {
                    setCovers(originalCovers)
                    setCurrentPage(1)
                    setIsOriginal(true)
                  }
                }}
                style={{ outline: 'none' }}
              />
              <button
                onClick={() => findByText()}
                className="bg-purple-500 text-white p-2 rounded-md"
              >
                Rechercher
              </button>
            </div>

            <img
              onClick={handleExportClick}
              src={xlsIcon}
              className="w-8 h-8 mx-2 hover:cursor-pointer"
            />
            
            <RefreshOutlined className="text-gray-500 text-3xl hover:cursor-pointer" />
          </div>
        </div>
      )}

      <div className="border border-gray-300 text-sm mt-10 rounded-md">
        <div className="flex p-3 bg-gray-500 text-white items-center justify-between mb-2 py-3">
          <p className="w-[11%] text-center">DATE DE LA DEMANDE</p>
          <p className="w-[11%] text-center">CLIENT</p>
          <p className="w-[11%] text-center">SIRET</p>
          <p className="w-[11%] text-center">TYPE D'ASSURANCE</p>
          <p className="w-[11%] text-center">MONTANT DEMANDE</p>
          <p className="w-[11%] text-center">MONTANT ACCORDE</p>
          <p className="w-[11%] text-center">FACTURE EN COURS</p>
          <p className="w-[11%] text-center">ETAT</p>
          <p className="w-[11%] text-center">ACTION</p>
        </div>
      </div>

      {!isATraiter && (
        <div className="flex flex-col space-y-2">
          {covers.map((cover, idx) => {
            return (
              <div
                key={idx}
                className="flex bg-white shadow-lg text-xs border border-gray-300 rounded-md p-3 items-center space-x-1"
              >
                <p className="w-[11%]  text-center">
                  {formatJsDate(cover._doc.decisionDate)}
                </p>
                <p className="w-[11%]  text-center">
                  {cover._doc.client.companyName}
                </p>
                <p className="w-[11%]  text-center">
                  {cover._doc.client.siret}
                </p>
                <p className="w-[11%]  text-center">{cover._doc.coverType}</p>
                <p className="w-[11%]  text-center">
                  {cover._doc.creditLimitApplicationAmountInPolicyCurrency}
                  &euro;
                </p>
                <p className="w-[11%]  text-center">
                  {cover._doc.totalDecision.decisionAmtInPolicyCurrency}&euro;
                </p>
                <p className="w-[11%]  text-center">
                  {cover.montantNonFacture.toFixed(2)}&euro;
                </p>
                {cover.montantNonFacture <=
                  cover._doc.totalDecision.decisionAmtInPolicyCurrency &&
                  cover._doc.totalDecision.decisionAmtInPolicyCurrency > 0 && (
                    <p className="w-[11%] text-green-600  text-center">OK</p>
                  )}
                {cover._doc.totalDecision.decisionAmtInPolicyCurrency ===
                  '0' && (
                  <p className="w-[11%] text-red-600  text-center uppercase">
                    refusée
                  </p>
                )}
                {cover._doc.totalDecision.decisionAmtInPolicyCurrency !== '0' &&
                  cover.montantNonFacture >
                    cover._doc.totalDecision.decisionAmtInPolicyCurrency && (
                    <p className="w-[11%] text-red-600 font-semibold  text-center">
                      A REVOIR
                    </p>
                  )}
                <p className="w-[11%]  text-center">
                  <Popover
                    placement="bottom"
                    content={
                      <div className="flex flex-col capitalize text-base space-y-3">
                        <p
                          onClick={() => {
                            setSelectedCover(cover._doc)
                            setIsReNew(true)
                          }}
                          className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                        >
                          <span>Nouvelle couverture</span>
                        </p>
                        <p
                          onClick={() => {
                            cancel(cover._doc)
                          }}
                          className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                        >
                          <span>Annuler la couverture</span>
                        </p>

                        <p
                          onClick={() => refresh(cover._doc)}
                          className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                        >
                          <span>Rafraichir</span>
                        </p>

                        <p
                          onClick={() => {
                            setSelectedCoverATraiter(cover)
                            setIsVoirTraitement(true)
                          }}
                          className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                        >
                          <span>Voir traitement</span>
                        </p>
                      </div>
                    }
                    trigger="click"
                  >
                    <Button>...</Button>
                  </Popover>
                </p>
              </div>
            )
          })}
        </div>
      )}

      {isATraiter && (
        <div className="flex flex-col space-y-2">
          {coversToUpdate.map((cover, idx) => {
            return (
              <div
                key={idx}
                className="flex bg-white shadow-lg text-xs border border-gray-300 rounded-md p-3 items-center space-x-1"
              >
                <p className="w-[11%]  text-center">
                  {formatJsDate(cover.decisionDate)}
                </p>
                <p className="w-[11%]  text-center">
                  {cover.client.companyName}
                </p>
                <p className="w-[11%]  text-center">{cover.client.siret}</p>
                <p className="w-[11%]  text-center">{cover.coverType}</p>
                <p className="w-[11%]  text-center">
                  {cover.creditLimitApplicationAmountInPolicyCurrency}&euro;
                </p>
                <p className="w-[11%]  text-center">
                  {cover.totalDecision.decisionAmtInPolicyCurrency}&euro;
                </p>
                <p className="w-[11%]  text-center">
                  {/*  {cover.montantNonFacture.toFixed(2)}&euro; */}
                </p>
                {/* {cover.montantNonFacture <=
                cover.totalDecision.decisionAmtInPolicyCurrency &&
                cover.totalDecision.decisionAmtInPolicyCurrency > 0 && (
                  <p className="w-[11%] text-green-600  text-center">OK</p>
                )} */}
                {cover.totalDecision.decisionAmtInPolicyCurrency === '0' && (
                  <p className="w-[11%] text-red-600  text-center uppercase">
                    refusée
                  </p>
                )}
                {cover.totalDecision.decisionAmtInPolicyCurrency !== '0' &&
                  cover.montantNonFacture >
                    cover.totalDecision.decisionAmtInPolicyCurrency && (
                    <p className="w-[11%] text-red-600 font-semibold  text-center">
                      A REVOIR
                    </p>
                  )}
                <p className="w-[11%]  text-center">
                  <Popover
                    placement="bottom"
                    content={
                      <div className="flex flex-col capitalize text-base space-y-3">
                        <p
                          onClick={() => {
                            setIsTraiterCoverModal(true)
                            setSelectedCoverATraiter(cover)
                            console.log(cover)
                          }}
                          className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                        >
                          <span>Traiter</span>
                        </p>
                      </div>
                    }
                    trigger="click"
                  >
                    <Button>...</Button>
                  </Popover>
                </p>
              </div>
            )
          })}
        </div>
      )}

      {isOriginal && !isATraiter && (
        <div className="text-right mr-2">
          <Pagination
            className="mt-6 w-full"
            current={currentPage}
            onChange={onChangePage}
            total={documentCount}
            pageSize={20}
          />
        </div>
      )}
    </div>
  )
}

export default Atradius
