import LocationOnIcon from '@mui/icons-material/LocationOn'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import axiosInstance from '../../axios/axiosInstance'
import { useEffect, useRef, useState } from 'react'
import { AddCircleOutlineOutlined, CalendarMonth } from '@mui/icons-material'
import jsPDF from 'jspdf'
import { Button, Input, Popconfirm, Popover, Spin, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import { createPdfCandidate, createVacationPdf } from '../utils/pdfUtils'
import { Pagination } from 'antd'
import xlsIcon from '../../assets/img/xls.png'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import { Puff } from 'react-loader-spinner'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { saveAs } from 'file-saver'

export default function CandidatContract() {
  const [contracts, setContracts] = useState([])
  const [originalContracts, setOriginalContracts] = useState([])
  const [vacationContracts, setVacationContracts] = useState([])
  const [originalVacationContracts, setOriginalVacationContracts] = useState([])
  const [loadingContract, setLoadingContract] = useState(false)
  const [isInterim, setIsInterim] = useState(true)
  const [isVacation, setIsVacation] = useState(false)
  const [documentCount, setDocumentCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchWord, setSearchWord] = useState('')
  const [isSearch, setIsSearch] = useState(false)
  const [loadingSearch, setLoadingSearch] = useState(false)

  const pdfRef = useRef(null)
  const navigate = useNavigate()

  const search = () => {
    if (searchWord.length < 2) {
      message.error('Minimum trois caracteres !')
      return
    }
    setIsSearch(true)
    setLoadingSearch(true)
    let target = isInterim ? 'pld-contract' : 'contract'

    axiosInstance
      .post(`${target}/find-by-text`, { query: searchWord })
      .then((res) => {
        console.log(res.data)
        if (target === 'contract') setVacationContracts(res.data)
        else setContracts(res.data)
      })
      .catch((e) => {
        console.log(e)
        message.error("Une erreur s'est produite !")
      })
      .finally(() => setLoadingSearch(false))
  }

  const formatDate = (inputDate) => {
    const year = inputDate.substring(0, 4)
    const month = inputDate.substring(4, 6)
    const day = inputDate.substring(6, 8)

    return `${day}/${month}/${year}`
  }

  const selectSearchWord = (word) => {
    if (word.length === 0) {
      setContracts(originalContracts)
      setVacationContracts(originalVacationContracts)
      setCurrentPage(0)
      setIsSearch(false)
    }

    setSearchWord(word)
  }

  const deleteContract = (e, contractId) => {
    e.stopPropagation()
    axiosInstance
      .delete(`contract/${contractId}`)
      .then((res) => {
        const contractIdx = vacationContracts.findIndex(
          (cntr) => cntr._id === contractId,
        )
        const newContracts = [...vacationContracts]
        newContracts.splice(contractIdx, 1)
        setVacationContracts(newContracts)
        message.success('Contrat supprimé !')
      })
      .catch((e) => {
        console.log(e)
        message.error("Vous n'etes pas autorisé à supprimer le contract !")
      })
  }

  const formatJsDate = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${day}/${month}/${year}`
  }

  const generatePDF = async (contract) => {
    /*  if (!contract.signedByUser) { */
    const pdf = new jsPDF({
      unit: 'mm',
      format: [210, 297],
    })
    try {
      const pdfFile = await createPdfFile(contract, pdf)
      const pdfBlob = pdfFile.output('blob')
      const pdfUrl = URL.createObjectURL(pdfBlob)
      console.log(pdfUrl)
      window.open(pdfUrl, '_blank')
      setLoadingContract(false)
    } catch (e) {
      console.log(e)
      setLoadingContract(false)
    }
    /*  } */
    /*   else {
      axiosInstance
        .get(`pld-contract/${contract._id}`)
        .then((res) => {
          console.log(res.data.userData)
          const base64Data = res.data.userData
          const pdfBlob = base64ToBlob(base64Data, 'application/pdf')
          saveAs(
            pdfBlob,
            `${res.data.NomPrenom}_${res.data.NumeroContrat}-${res.data.NumeroAvenant}.pdf`,
          )
        })
        .catch((e) => console.log(e))
    } */
  }

  const base64ToBlob = (base64, contentType) => {
    const byteCharacters = atob(base64) // Decode Base64
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    return new Blob([byteArray], { type: contentType })
  }

  const generateVacationPDF = async (contract) => {
    const pdf = new jsPDF({
      unit: 'mm',
      format: [210, 297],
    })
    console.log(contract)
    try {
      const pdfFile = await createVacationPdfFile(contract, pdf)
      const pdfBlob = pdfFile.output('blob')
      const pdfUrl = URL.createObjectURL(pdfBlob)
      console.log(pdfUrl)
      window.open(pdfUrl, '_blank')
    } catch (e) {
      console.log(e)
      message.error("Une erreur s'est produite !")
    }
    setLoadingContract(false)
  }

  const createPdfFile = async (contract, pdf) => {
    setLoadingContract(true)
    return await createPdfCandidate(contract, pdf)
  }

  const createVacationPdfFile = async (contract, pdf) => {
    setLoadingContract(true)
    return await createVacationPdf(contract, pdf)
  }

  useEffect(() => {
    axiosInstance
      .get('tempworker-candidate/interimaire')
      .then((res) => {
        const set = []
        res.data.map((r) => {
          let index = set.findIndex((rr) => rr._id === r._id)
          if (index === -1) set.push(r)
        })
        console.log(res.data.length)
        console.log(set.length)
      })
      .catch((e) => console.log(e))

    axiosInstance
      .get('pld-contract/count')
      .then((res) => setDocumentCount(res.data))
      .catch((e) => {})

    loadContracts()
    loadVacationContracts()
  }, [])

  const onChangePage = (page, pageSize) => {
    setLoadingSearch(true)

    let target = isInterim ? 'pld-contract' : 'contract'
    axiosInstance
      .get(`${target}/paginate/${page - 1}`)
      .then((res) => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth', // Optional: smooth scrolling animation
        })
        if (target === 'contract') setVacationContracts(res.data)
        else setContracts(res.data)
        setCurrentPage(page)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoadingSearch(false)
      })
  }

  const dematContract = (contractId) => {
    axiosInstance
      .patch(`pld-contract/demat/${contractId}`, { isDemat: true })
      .then((res) => {
        console.log(res.data)
        message.success('Contrat dematerialisé !')
      })
      .catch((e) => {
        message.error("Une erreur s'est produite !")
      })
  }

  const switchToInterim = (isInterim) => {
    if (isInterim) {
      setIsInterim(true)
      setIsVacation(false)
      axiosInstance
        .get('pld-contract/count')
        .then((res) => setDocumentCount(res.data))
        .catch((e) => {})
    } else {
      setIsInterim(false)
      setIsVacation(true)
      axiosInstance
        .get('contract/count')
        .then((res) => setDocumentCount(res.data))
        .catch((e) => {})
    }
    setCurrentPage(1)
  }
  const loadVacationContracts = () => {
    axiosInstance
      .get('contract')
      .then((data) => {
        console.log(data.data)
        setVacationContracts(data.data)
        setOriginalVacationContracts(data.data)
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }

  const relanceSignature = (contractId) => {
    axiosInstance
      .get(`pld-contract/relance-signature/${contractId}`)
      .then((res) => message.success('Relance envoyée'))
      .catch((e) => console.log(e))
  }

  const loadContracts = () => {
    axiosInstance
      .get('pld-contract')
      .then((data) => {
        console.log(data.data)
        setContracts(data.data)
        console.log(data.data[0].agency.signatureImg)
        setOriginalContracts(data.data)
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }

  return (
    <>
      {loadingSearch && (
        <div className="w-full h-full absolute top-0 z-30 right-0 bg-gray-400 bg-opacity-40 flex items-center justify-center">
          <Puff
            height="80"
            width="80"
            radius={1}
            color="#4fa94d"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
      <div className="flex justify-end ml-20 mb-10 mt-10">
        <div className="flex items-center space-x-2">
          <Input
            className="w-80  rounded-md border border-gray-200 focus:outline-none"
            placeholder="Recherche"
            style={{ outline: 'none' }}
            onChange={(e) => selectSearchWord(e.target.value)}
          />
          <button
            onClick={() => search()}
            className="bg-green-500 text-white p-2 rounded-md"
          >
            Rechercher
          </button>

          <img src={xlsIcon} className="w-8 h-8 mx-2 hover:cursor-pointer" />
          <FilterListOutlinedIcon className="w-8 h-8 text-gray-500 hover:cursor-pointer" />
        </div>
      </div>

      <div className="w-3/5 mx-auto flex items-center justify-between text-lg font-semibold mt-6 mb-12">
        <p
          className={`${isInterim ? 'underline' : ''}`}
          onClick={() => {
            switchToInterim(true)
          }}
        >
          Interim
        </p>
        <p
          className={`${isVacation ? 'underline' : ''}`}
          onClick={() => {
            switchToInterim(false)
          }}
        >
          Vacation
        </p>
      </div>
      <div className="flex items-center justify-between  mb-6 mx-20 text-xs">
        <p className="w-1/6 text-center  animate-fadeIn">N° DE CONTRAT</p>
        <p className="w-1/6 text-center">ENTREPRISE</p>
        <p className="w-1/6 text-center">INTERIMAIRE</p>
        <p className="w-1/6 text-center">DATE DE CONTRAT</p>
        <p className="w-1/6 text-center">SIGNATURE</p>
        <p className="w-1/6 text-center">ACTION</p>
      </div>

      {loadingContract && (
        <div className="flex items-center justify-center my-3 ">
          <Spin className="w-20" />
        </div>
      )}
      {isInterim && (
        <div className="flex flex-col space-y-4">
          {contracts.map((contract, idx) => {
            return (
              <div
                key={idx}
                className=" group  flex  items-center drop-shadow-2xl text-xs  justify-between mx-20 bg-white border  border-gray-200 rounded-md p-4 hover:scale-101 hover:rotate-y-90 hover:cursor-pointer transition-all duration-300"
              >
                <div className="w-1/6">
                  <p className="font-bold">J'M INTERIM</p>
                  <p className="text-gray-500">Asnieres sur seine</p>
                  <p className="text-gray-500">{contract.IdContrat}</p>
                </div>

                <div className="w-1/6 flex items-center space-x-2">
                  <div>
                    <p>
                      <LocationOnIcon className="text-3xl text-gray-300" />
                    </p>
                  </div>
                  <div className="text-xs">
                    <p className="font-bold">{contract.RaisonSociale}</p>
                    <p className="text-gray-500">
                      {' '}
                      {contract.AdresseRue} - {contract.AdresseCodePostal}
                    </p>
                    <p className="text-gray-500">{contract.AdresseVille}</p>
                  </div>
                </div>

                <div className="w-1/6 flex items-center space-x-2">
                  <AccountBoxIcon className="text-3xl text-gray-300" />
                  <div>
                    <p className="font-bold text-xs">{contract.NomPrenom}</p>
                    <p className="text-xs text-gray-500">
                      {contract.Profession}
                    </p>
                  </div>
                </div>

                <div className="w-1/6 flex items-center space-x-2 justify-center">
                  <div>
                    <p>
                      <CalendarMonth className="text-3xl text-gray-300" />
                    </p>
                  </div>
                  <div className="text-xs">
                    <p className="font-bold text-right">
                      Du {formatDate(contract.DateDebut)}
                    </p>
                    <p className="font-bold text-right">
                      au {formatDate(contract.DateFin)}
                    </p>
                  </div>
                </div>

                <div className="w-1/6 flex flex-col items-center space-y-1 justify-center">
                  {!contract.isDematUser && (
                    <p className="text-red-400 font-semibold">
                      A dematerialiser
                    </p>
                  )}
                  {contract.signedByUser && (
                    <p className="text-green-400 font-semibold">
                      Signé par l'interimaire
                    </p>
                  )}
                </div>

                <div className="w-1/6 flex items-center space-x-2 justify-center">
                  <Popover
                    placement="bottom"
                    content={
                      <div className="flex flex-col capitalize text-base space-y-3">
                        <p
                          onClick={() => generatePDF(contract)}
                          className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                        >
                          <span>Visualiser</span>
                        </p>
                        <p
                          onClick={() => generatePDF(contract)}
                          className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                        >
                          <span>Télécharger</span>
                        </p>
                        <p
                          className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                        >
                          <span>Mission abandonnée</span>
                        </p>
                        <p
                          className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                        >
                          <span>Mission non demarée</span>
                        </p>
                        <p
                          className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                        >
                          <span>Embauche prevue</span>
                        </p>
                        <p
                          className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                        >
                          <span>Contrat non lieu</span>
                        </p>
                        {!contract.isDematUser && (
                          <p
                            onClick={() => dematContract(contract._id)}
                            className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                          >
                            <span>Dematerialiser</span>
                          </p>
                        )}
                        {!contract.signedByUser && contract.isDematUser && (
                          <p
                            onClick={() => relanceSignature(contract._id)}
                            className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                          >
                            <span>Relancer signature interimaire</span>
                          </p>
                        )}
                      </div>
                    }
                  >
                    <Button>...</Button>
                  </Popover>
                </div>

                {/*   <div className="w-1/5 flex justify-center items-center">
                <p
                  className={`hidden  text-gray-500 p-1 rounded-md text-sm w-fit group-hover:flex items-center space-x-2 group-hover:animate-fadeIn`}
                >
                  <SearchIcon  />
                  <SimCardDownloadIcon onClick={() => downloadPdf(contract)} />
                </p>
              </div> */}
              </div>
            )
          })}
        </div>
      )}

      {isVacation && (
        <div className="flex flex-col space-y-4">
          {vacationContracts.map((contract, idx) => {
            return (
              <div
                key={idx}
                className=" group  flex  items-center drop-shadow-2xl text-xs  justify-between mx-20 bg-white border  border-gray-200 rounded-md p-4 hover:scale-101 hover:rotate-y-90 hover:cursor-pointer transition-all duration-300"
              >
                <div
                  onClick={() => generateVacationPDF(contract)}
                  className="w-1/4"
                >
                  <p className="font-bold">J'M INTERIM</p>
                  <p className="text-gray-500">Asnieres sur seine</p>
                  <p className="text-gray-500">{contract.contractNumber}</p>
                </div>

                <div
                  onClick={() => generateVacationPDF(contract)}
                  className="w-1/4 flex items-center space-x-2"
                >
                  <div>
                    <p>
                      <LocationOnIcon className="text-3xl text-gray-300" />
                    </p>
                  </div>
                  <div className="text-xs">
                    <p className="font-bold">{contract.client.companyName}</p>
                    <p className="text-gray-500">
                      {' '}
                      {contract.client.address} - {contract.client.postCode}
                    </p>
                    <p className="text-gray-500">{contract.client.town}</p>
                  </div>
                </div>

                <div
                  onClick={() => generateVacationPDF(contract)}
                  className="w-1/4 flex items-center space-x-2"
                >
                  <AccountBoxIcon className="text-3xl text-gray-300" />
                  <div>
                    <p className="font-bold text-xs">
                      {contract.candidate.firstname}{' '}
                      {contract.candidate.lastname}
                    </p>
                    <p className="text-xs text-gray-500">
                      {contract.qualification}
                    </p>
                  </div>
                </div>

                <div
                  onClick={() => generateVacationPDF(contract)}
                  className="w-1/4 flex items-center space-x-2 justify-center"
                >
                  <div>
                    <p>
                      <CalendarMonth className="text-3xl text-gray-300" />
                    </p>
                  </div>
                  <div className="text-xs">
                    {contract.dates.map((date) => {
                      return (
                        <p className="font-bold text-right">
                          {formatJsDate(date.dateDebut)}
                        </p>
                      )
                    })}
                  </div>
                </div>

                <div className="text-right">
                  <Popconfirm
                    title="Supprimer le contract"
                    description="Voulez vous annuler ce contrat ?"
                    onConfirm={(e) => deleteContract(e, contract._id)}
                    okText="Oui"
                    cancelText="Non"
                    okButtonProps={{ className: 'bg-blue-600 text-white' }}
                  >
                    <DeleteForeverIcon className="text-3xl text-gray-600" />
                  </Popconfirm>
                </div>
              </div>
            )
          })}
        </div>
      )}

      {!isSearch && (
        <div className="flex  mx-16 justify-end">
          <Pagination
            className="mt-6"
            current={currentPage}
            onChange={onChangePage}
            total={documentCount}
            pageSize={48}
          />
        </div>
      )}
    </>
  )
}
