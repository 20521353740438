import { message } from 'antd'
import axios from '../../../axios/axiosInstance'
import { useState, useRef } from 'react'
import { Page, Document } from 'react-pdf'

const ModalCv = ({ setIsModalCV, selectedUser, cvData, cvType }) => {
  const [numPages, setNumPages] = useState([])

  const fileInputRef = useRef(null)

  const handleDocumentLoadSuccess = ({ numPages }) => {
    const newArray = Array.from({ length: numPages }, (_, index) => index + 1)
    setNumPages(newArray)
  }

  const handleButtonClick = (e) => {
    e.preventDefault()
    fileInputRef.current.click() // Programmatically trigger the file input
  }

  const handleDownloadPdf = () => {
    // Generate a temporary anchor element and set the PDF file as its href
    const link = document.createElement('a')
    link.href = `data:application/pdf;base64,${cvData}`
    link.download = `${selectedUser.firstname}-${selectedUser.lastname}.pdf`

    // Simulate a click event to trigger the download
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const updateCv = (e) => {
    const formData = new FormData()
    const file = e.target.files[0]
    formData.append('file', file)

    if (file.type === 'application/pdf') {
      axios
        .post(`cv/${selectedUser._id}/update-cv`, formData)
        .then((res) => {
          console.log(res.data)
          setIsModalCV(false)
          message.success('Cv modifie!')
        })
        .catch((e) => console.log(e))
    } else {
      message.error('Veuillez ajouter un fichier pdf !')
    }
  }

  return (
    <div className="fixed w-full inset-0 z-30 flex items-center justify-center bg-black bg-opacity-70">
      <div className="flex flex-col items-center justify-start py-6 bg-white rounded-md max-h-[90vh] overflow-y-auto w-full ">
        <div className="flex w-full items-center my-3 justify-center space-x-4">
          <p
            className="cursor-pointer bg-white px-2 py-1 text-black rounded-md border border-black"
            onClick={handleDownloadPdf}
          >
            Telechargez le CV
          </p>
          <p
            className="cursor-pointer bg-white px-2 py-1 text-black rounded-md border border-black"
            onClick={handleButtonClick}
          >
            Modifier le CV
          </p>
          <p
            className="cursor-pointer bg-white px-2 py-1 text-black rounded-md border border-black"
            onClick={() => setIsModalCV(false)}
          >
            Fermer
          </p>
        </div>

        {/* Check if file is PDF or image */}
        {cvData &&
          (cvType === 'pdf' ? (
            // Display PDF using the Document component
            <Document
              file={`data:application/pdf;base64,${cvData}`}
              onLoadSuccess={handleDocumentLoadSuccess}
            >
              {numPages.map((x, idx) => (
                <Page key={idx} scale={5 / 3} pageNumber={idx + 1} />
              ))}
            </Document>
          ) : (
            // Display image (JPEG/PNG)
            <img
              src={`data:image/${cvType};base64,${cvData}`}
              alt="CV Preview"
              className="w-full h-auto"
            />
          ))}

        <input
          type="file"
          onChange={(e) => updateCv(e)}
          className="hidden"
          ref={fileInputRef}
        />
      </div>
    </div>
  )
}

export default ModalCv
