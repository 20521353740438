import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import { Button, Card, Checkbox, Input, Modal, Pagination, message } from 'antd'
import { useEffect, useRef, useState } from 'react'

import CandidatModal from '../CandidatModal'
import CreateCandidatModal from '../CreateCandidatModal'
import FilterCandidat from '../FilterCandidat'
import AvailableCandidat from '../../AvailableCandidat/AvailableCandidat'
import ModalCv from './ModalCv'
import LoadingSearch from './LoadingSearch'
import CVTheque from './CVTheque'

const CandidatList = () => {
  const [username, setUsername] = useState('')
  const [isCVTheque, setIsCVTheque] = useState(true)

  useEffect(() => {
    const username = localStorage.getItem('name')
    setUsername(username)
  }, [])

  return (
    <div>
      <div className="mt-10 mb-6 flex py-2 border border-gray-300 px-2 bg-[#F7F9FC] space-x-2 rounded-md items-center justify-between">
        <div
          onClick={() => setIsCVTheque(true)}
          className={`${
            isCVTheque ? 'bg-white border border-gray-200' : ''
          } py-4  w-1/2 hover:cursor-pointer rounded-xl`}
        >
          <p className="text-center font-bold">CVthèque</p>
        </div>

        <div
          onClick={() => setIsCVTheque(false)}
          className={`${
            !isCVTheque ? 'bg-white border border-gray-200' : ''
          } py-4 w-1/2 hover:cursor-pointer  rounded-xl`}
        >
          <p className="text-center font-bold">Candidats disponibles</p>
        </div>
      </div>

      {isCVTheque && <CVTheque />}
      {!isCVTheque && <AvailableCandidat />}
    </div>
  )
}

export default CandidatList
