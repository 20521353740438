import { Puff } from 'react-loader-spinner'

const LoadingSearch = () => {
  return (
    <div
      className="fixed  inset-0 z-30 bg-gray-400 bg-opacity-40 flex items-center justify-center"
      style={{ top: 0, left: 0 }}
    >
      <Puff
        height="80"
        width="80"
        radius={1}
        color="#4fa94d"
        ariaLabel="puff-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  )
}

export default LoadingSearch
