import {
  Button,
  DatePicker,
  Drawer,
  Input,
  Modal,
  Popover,
  Select,
  message,
  Pagination,
} from 'antd'
import { useEffect, useRef, useState } from 'react'
import axiosInstance from '../../axios/axiosInstance'
import SearchIcon from '@mui/icons-material/Search'
import dayjs from 'dayjs'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import ValidateAcompte from './ValidateAcompte'
import ModifyStatus from './ModifyStatus'
import RefuseAcompte from './RefuseAcompte'

const Acompte = () => {
  const [openModal, setOpenModal] = useState(true)
  const [acomptes, setAcomptes] = useState([])
  const [originalAcomptes, setOriginalAcomptes] = useState([])
  const [filter, setFilter] = useState(false)
  const [statusFilter, setStatusFilter] = useState('')
  const [timer, setTimer] = useState(null)
  const [showSearch, setShowSearch] = useState(false)
  const [userRole, setUserRole] = useState('')
  const [validerAcompte, setValiderAcompte] = useState(false)
  const [refuserAcompte, setRefuserAcompte] = useState(false)
  const [modifierAcompte, setModifierAcompte] = useState(false)
  const [selectedAcompteId, setSelectedAcompteId] = useState(null)
  const [documentCount, setDocumentCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)

  const searchInputRef = useRef(null)

  useEffect(() => {
    loadAcomptes()
    const userRole = localStorage.getItem('userRole')
    setUserRole(userRole)
  }, [])

  const search = (word) => {
    if (word.length === 0) setAcomptes(originalAcomptes)
    if (word.length >= 3) {
      axiosInstance
        .post('acompte/find-by-text', { query: word })
        .then((res) => setAcomptes(res.data))
    }
  }

  const showSearchInput = (search) => {
    setShowSearch(!search)
    if (!search)
      setTimeout(() => {
        searchInputRef.current.focus()
      }, 100)
  }
  const validateAcompte = (acompteId) => {
    setValiderAcompte(true)
    setSelectedAcompteId(acompteId)
  }

  const modifyAcompte = (acompteId) => {
    setModifierAcompte(true)
    setSelectedAcompteId(acompteId)
  }

  const refuseAcompte = (acompteId) => {
    setRefuserAcompte(true)
    setSelectedAcompteId(acompteId)
  }

  const getFilteredAcompte = () => {
    axiosInstance
      .get(`acompte/filter?status=${statusFilter}`)
      .then((res) => setAcomptes(res.data))
      .catch((e) => console.log(e))
  }

  const updateAcompte = (
    acompteId,
    acompteStatus,
    acompteValidatedAmount,
    acomptePaymentDate,
    acompteMotif,
  ) => {
    const acompteIndex = acomptes.findIndex((obj) => obj._id === acompteId)
    const acompte = acomptes[acompteIndex]
    if (
      userRole === 'ADMIN' &&
      (acompte.status === 'Refusée' || acompte.status === 'Validée')
    ) {
      message.error('Vous ne pouvez pas modifier !')
      return
    }

    if (timer) {
      clearTimeout(timer)
    }
    const date =
      acomptePaymentDate && acomptePaymentDate.$d
        ? acomptePaymentDate.$d
        : acomptePaymentDate

    const newAcomptes = [...acomptes]
    newAcomptes[acompteIndex] = {
      ...acomptes[acompteIndex],
      validatedAmount: acompteValidatedAmount,
      status: acompteStatus,
      paymentDate: date,
      motif: acompteMotif,
    }
    setAcomptes(newAcomptes)
    const newTimer = setTimeout(() => {
      axiosInstance
        .put(`acompte/${acompteId}`, {
          status: acompteStatus,
          validatedAmount: acompteValidatedAmount,
          paymentDate: date,
          motif: acompteMotif,
        })
        .then((res) => {
          message.success('Modification enregistrées')
        })
        .catch((e) => {
          message.error('Vous ne pouvez pas modifier !')
        })
    }, 1000)

    setTimer(newTimer)
  }

  const openAcompteModal = () => {
    setOpenModal(true)
  }

  const formatJsDate = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${day}/${month}/${year}`
  }

  const onClose = (acompte) => {
    console.log(acompte)
    const acompteIndex = acomptes.findIndex((acpt) => acompte._id === acpt._id)
    console.log(acompteIndex)
    const newAcomptes = [...acomptes]
    newAcomptes[acompteIndex] = acompte
    console.log(newAcomptes)
    setAcomptes(newAcomptes)
  }

  const loadAcomptes = () => {
    axiosInstance
      .get('acompte/count')
      .then((res) => setDocumentCount(res.data))
      .catch((e) => {})

    axiosInstance
      .get('acompte')
      .then((res) => {
        console.log(res.data)
        setOriginalAcomptes(res.data)
        setAcomptes(res.data)
      })
      .catch((e) => {
        message.error("Une erreur s'est produite")
      })
  }

  const onChangePage = (page, pageSize) => {
    setIsLoading(true)
    axiosInstance
      .get(`acompte/paginate/${page - 1}`)
      .then((res) => {
        setAcomptes(res.data)
        setIsLoading(false)
        setCurrentPage(page)
        console.log(res.data)
      })
      .catch((e) => {
        console.log(e)
        setIsLoading(false)
      })
  }

  return (
    <div className="font-Nunito">
      {validerAcompte && (
        <ValidateAcompte
          acompteId={selectedAcompteId}
          onClose={onClose}
          validerAcompte={validerAcompte}
          setValiderAcompte={setValiderAcompte}
        />
      )}

      {refuserAcompte && (
        <RefuseAcompte
          acompteId={selectedAcompteId}
          onClose={onClose}
          refuserAcompte={refuserAcompte}
          setRefuserAcompte={setRefuserAcompte}
        />
      )}
      {modifierAcompte && (
        <ModifyStatus
          acompteId={selectedAcompteId}
          onClose={onClose}
          modifierAcompte={modifierAcompte}
          setModifierAcompte={setModifierAcompte}
        />
      )}
      <Drawer
        className="my-10 h-96 rounded-l-2xl flex flex-col items-center"
        title="Filtrer acompte"
        placement="right"
        onClose={() => setFilter(false)}
        open={filter}
      >
        <Select
          className={`w-4/5 mx-auto  font-light rounded-md border`}
          optionFilterProp="children"
          onChange={(e) => {
            setStatusFilter(e)
          }}
          value={statusFilter}
          placeholder="Selectionner"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={[
            {
              value: 'En cours de traitement',
              label: 'En cours de traitement',
              key: '0',
            },
            {
              value: 'En attente des RH',
              label: 'En attente des RH',
              key: '1',
            },
            {
              value: 'En attente du RIB',
              label: 'En attente du RIB',
              key: '2',
            },

            {
              value: 'Validée',
              label: 'Validée',
              key: '3',
            },
            {
              value: 'Refusée',
              label: 'Refusée',
              key: '4',
            },
          ]}
        />
        <div className="flex items-center justify-center space-x-2">
          <Button
            onClick={getFilteredAcompte}
            className="bg-blue-400 text-white mt-6 text-center  rounded-md px-4 py-2 w-fit flex items-center justify-center"
          >
            <span>Appliquer</span>
          </Button>
          <Button
            onClick={() => {
              setAcomptes(originalAcomptes)
              setStatusFilter('')
            }}
            className="bg-gray-400 text-black mt-6 text-center  rounded-md px-4 py-2 w-fit flex items-center justify-center"
          >
            <span>Reinitialiser</span>
          </Button>
        </div>
      </Drawer>

      <div className="mb-10 mt-10 flex items-center justify-between">
        <div>
          <p className="text-xl text-gray-400">Acceuil / Gestion / Acomptes</p>
          <p className="text-2xl font-semibold">Liste des acomptes</p>
        </div>

        <div className="flex items-center space-x-2">
          <Input
            className={`w-80 rounded-md border border-gray-200 focus:outline-none`}
            placeholder="Recherche"
            style={{ outline: 'none' }}
            onChange={(e) => search(e.target.value)}
          />
          <FilterListOutlinedIcon
            onClick={() => setFilter(true)}
            className="text-gray-500 text-3xl"
          />
        </div>
      </div>

      {/* <div className="flex justify-end ml-20 ">          
        <div className="flex items-center space-x-2 h-10">
          {showSearch && (
            <Input
              ref={searchInputRef}
              className={`w-80 transition-all ${
                showSearch ? 'opacity-100' : 'opacity-0'
              } duration-500 ease-in-out   rounded-md border border-gray-200 focus:outline-none`}
              placeholder="Recherche"
              style={{ outline: 'none' }}
              onChange={(e) => search(e.target.value)}
            />
          )}
          <SearchIcon
            onClick={() => showSearchInput(showSearch)}
            className="text-gray-500 text-3xl"
          />
          <FilterListOutlinedIcon
            onClick={() => setFilter(true)}
            className="text-gray-500 text-3xl"
          />
        </div>
      </div> */}

      <div className="border border-gray-300 rounded-md py-2">
        <div className="flex items-center justify-around  text-md py-3">
          <p className="w-[12%] text-center">DATE DEMANDE</p>
          <p className="w-[12%] text-center">NOM</p>
          <p className="w-[12%] text-center">MONTANT DEMANDE</p>
          <p className="w-[12%] text-center">MONTANT VERSE</p>
          <p className="w-[12%] text-center">DATE DE VERSEMENT</p>
          <p className="w-[12%] text-center">PROVENANCE</p>
          <p className="w-[12%] text-center">STATUT</p>
          <p className="w-[12%] text-center">ACTION</p>
        </div>
        <div className="flex flex-col">
          {acomptes.map((acompte, idx) => {
            return (
              <div
                className="flex  items-center drop-shadow-2xl text-xs  justify-around  bg-white border  border-gray-200 rounded-md p-4 hover:cursor-pointer"
                key={idx}
              >
                <p className="w-[12%] text-center text-lg capitalize">
                  {formatJsDate(acompte.date)}
                </p>
                <p className="w-[12%] text-center text-lg capitalize">
                  {acompte.name}
                </p>
                <p className="w-[12%] text-center text-lg capitalize">
                  {acompte.askedAmount} &euro;
                </p>

                {/*  <div className="w-1/6 flex justify-center">
                <Input
                  className="rounded-md border border-gray-200 focus:outline-none"
                  placeholder="Action menée"
                  value={arret.actionMenee}
                  style={{ outline: 'none' }}
                  onChange={(e) => updateAction(arret, e.target.value, idx)}
                />
              </div> */}

                <div className="w-[12%] flex items-center justify-center space-x-2">
                  <p className="text-lg capitalize">
                    {acompte.validatedAmount} &euro;
                  </p>
                </div>
                <div className="w-[12%] flex items-center justify-center space-x-2">
                  <p className="text-lg capitalize">
                    {acompte.paymentDate && formatJsDate(acompte.paymentDate)}
                  </p>
                </div>
                <div className="w-[12%] flex items-center justify-center">
                  <p className="text-lg capitalize">{acompte.provenance}</p>
                </div>
                <div className="w-[12%] text-lg text-center flex-col flex items-center justify-center">
                  {acompte.status === 'Validée' && (
                    <p className="bg-green-200 text-green-700 rounded-md text-lg w-fit p-2 ">
                      {acompte.status}
                    </p>
                  )}
                  {acompte.status !== 'Validée' &&
                    acompte.status !== 'Refusée' && (
                      <p className="bg-orange-200 text-orange-700 rounded-md text-lg w-fit p-2 ">
                        {acompte.status}
                      </p>
                    )}
                  {acompte.status === 'Refusée' && (
                    <p className="bg-red-200 text-red-700 rounded-md text-lg w-fit p-2 ">
                      {acompte.status}
                    </p>
                  )}
                  <p>{acompte.motif}</p>
                </div>
                <div className="w-[12%] flex items-center justify-center space-x-2">
                  <Popover
                    placement="bottom"
                    content={
                      <div className="flex flex-col capitalize text-base space-y-3">
                        <p
                          onClick={() => validateAcompte(acompte._id)}
                          className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                        >
                          <span>Valider</span>
                        </p>
                        <p
                          onClick={() => modifyAcompte(acompte._id)}
                          className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                        >
                          <span>Modifier le statut</span>
                        </p>
                        <p
                          onClick={() => refuseAcompte(acompte._id)}
                          className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                        >
                          <span>Refuser</span>
                        </p>
                      </div>
                    }
                    trigger="click"
                  >
                    <Button>...</Button>
                  </Popover>
                </div>
              </div>
            )
          })}
        </div>
        <div className="text-right mr-2">
          <Pagination
            className="mt-6 w-full"
            current={currentPage}
            onChange={onChangePage}
            total={documentCount}
            pageSize={48}
          />
        </div>
      </div>

      {/*  <div className="mt-10 flex text-xs items-center  mx-20">
        <p className="w-1/6 text-center">DATE DEMANDE</p>
        <p className="w-1/6 text-center">NOM</p>
        <p className="w-1/6 text-center">MONTANT DEMANDE</p>
        <p className="w-1/6 text-center">STATUT</p>
        <p className="w-1/6 text-center">MONTANT VERSE</p>
        <p className="w-1/6 text-center">DATE DE VERSEMENT</p>
      </div>

      <div className="mt-10 flex flex-col space-y-1">
        {acomptes.map((acompte) => {
          return (
            <div className="border transition-all duration-300 border-gray-300 rounded-md py-6 px-2 hover:scale-101 shadow-lg flex text-sm items-center  mx-20">
              <p className="w-1/6 text-center">{formatDate(acompte.date)}</p>
              <p className="w-1/6 text-center capitalize">{acompte.name}</p>
              <p className="w-1/6 text-center">{acompte.askedAmount} &euro;</p>
              <p className="w-1/6 text-center">
                <Select
                  className={`w-30  font-light rounded-md border
                    ${
                      acompte.status === 'En cours de traitement'
                        ? 'text-orange-400 bg-orange-400 border-orange-400'
                        : ''
                    }
                    ${
                      acompte.status === 'En attente des RH'
                        ? 'text-orange-600 bg-orange-600 border-orange-600'
                        : ''
                    }
                    ${
                      acompte.status === 'En attente du RIB'
                        ? 'text-orange-600 bg-orange-600 border-orange-600'
                        : ''
                    }
                    ${
                      acompte.status === 'Validée'
                        ? 'text-green-600 bg-green-600 border-green-600'
                        : ''
                    }
                    ${
                      acompte.status === 'Refusée'
                        ? 'text-red-600 bg-red-600 border-red-600'
                        : ''
                    }
                    `}
                  // class={'test'}
                  value={acompte.status}
                  optionFilterProp="children"
                  onChange={(e) =>
                    updateAcompte(
                      acompte._id,
                      e,
                      acompte.validatedAmount,
                      acompte.paymentDate,
                      acompte.motif,
                    )
                  }
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: 'En cours de traitement',
                      label: 'En cours de traitement',
                      key: '0',
                    },
                    {
                      value: 'En attente des RH',
                      label: 'En attente des RH',
                      key: '1',
                    },
                    {
                      value: 'En attente du RIB',
                      label: 'En attente du RIB',
                      key: '2',
                    },

                    {
                      value: 'Validée',
                      label: 'Validée',
                      key: '3',
                    },
                    {
                      value: 'Refusée',
                      label: 'Refusée',
                      key: '4',
                    },
                  ]}
                />
              </p>
              <p className="w-1/6 text-center flex items-center space-x-1">
                <Input
                  className="rounded-md border border-gray-300"
                  onChange={(e) =>
                    updateAcompte(acompte._id, acompte.status, e.target.value)
                  }
                  value={acompte.validatedAmount || ''}
                />
                <span>&euro;</span>
              </p>
              <p className="w-1/6 text-center">
                {acompte.status !== 'Refusée' && (
                  <DatePicker
                    onChange={(date, dateString) =>
                      updateAcompte(
                        acompte._id,
                        acompte.status,
                        acompte.validatedAmount,
                        date,
                      )
                    }
                    format={'DD/MM/YYYY'}
                    value={acompte.paymentDate && dayjs(acompte.paymentDate)}
                  />
                )}
                {acompte.status == 'Refusée' && (
                  <Input
                    className="w-4/5 rounded-md border border-gray-200 focus:outline-none"
                    onChange={(e) =>
                      updateAcompte(
                        acompte._id,
                        acompte.status,
                        acompte.validatedAmount,
                        acompte.paymentDate,
                        e.target.value,
                      )
                    }
                    placeholder="Motif"
                    value={acompte.motif}
                  />
                )}
              </p>
            </div>
          )
        })}
      </div> */}
    </div>
  )
}

export default Acompte
